.site-news{
	.flex{
		@include flex();
		@media screen and (max-width: $tab){
			display: block;	
		}
	}
	.container{
		width: calc(100% - 360px);
		order: 2;
		@media screen and (max-width: $tab){
			width: auto;	
		}
	}
	.sidebar{
		width: 360px;
		padding: 0 64px 0 0;
		order: 1;
		@media screen and (max-width: $tab){
			width: auto;
			padding: 48px 0 0;
		}
		@media screen and (max-width: $phone){
			padding: 32px 0 0;
		}
		&__box{
			margin: 0 0 56px;
			@media screen and (max-width: $tab){
				margin: 0 0 48px;	
			}
			@media screen and (max-width: $phone){
				margin: 0 0 24px;
			}
			&:last-of-type{
				margin: 0;
			}
		}
	}
	.bloglist{
		> li{
			@include flex();
			margin: 0 0 32px;
			padding: 0 0 32px;
			border-bottom: $border 1px solid;
			@media screen and (max-width: $phone){
				margin: 0 0 15px;
				padding: 0 0 15px;
			}
			a{
				@include flex();
				width: 100%;
				color: $normal;
				position: relative;
			}
			figure{
				width: 220px;
				height: 140px;
				@media screen and (max-width: $phone){
					width: 120px;
					height: 80px;
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
			dl{
				width: calc(100% - 220px);
				padding: 0 0 0 32px;
				@media screen and (max-width: $phone){
					width: calc(100% - 120px);
					padding: 0 0 0 15px;
				}
				.meta{
					@include flex();
					align-items: center;
					justify-content: flex-start;
					font-size: 13px;
					@media screen and (max-width: $phone){
						font-size: 11px;	
					}
					.category{
						@include flex();
						align-items: center;
						justify-content: flex-start;
					}
					time,
					li{
						border: $normal 1px solid;
						padding: 2px 16px;
						display: inline-block;
						margin: 0 10px 0 0;
						@media screen and (max-width: $phone){
							padding: 1px 12px;	
						}
					}
					li{
						background: $glay;
						border-color: $glay;
					}
				}
				dt{
					font-size: 20px;
					font-weight: 700;
					margin: 10px 0;
					@media screen and (max-width: $phone){
						font-size: 15px;	
					}
				}
				dd.desc{
					text-align: justify;
					font-size: 15px;
					line-height: 1.66;
					@media screen and (max-width: $phone){
						display: none;
					}
				}
			}
		}
        &.mini{
            > li{
				margin: 0 0 20px;
				padding: 0 0 20px;
				@media screen and (max-width: $phone){
					margin: 0 0 15px;
					padding: 0 0 15px;
				}
                a{
                    figure{
                        width: 80px;
						height: 50px;
                    }
                    dl{
                        width: calc(100% - 80px);
						padding: 0 0 0 20px;
						.meta{
							font-size: 10px;
						}
						dt{
							font-size: 14px;
							margin: 4px 0 0;
						}
                    }
                }
            }
        }
	}
    .categorylist{
		transform: translateY(-15px);
        li{
			border-bottom: $border 1px solid;
            a{
                padding: 15px 4px;
                padding-right: 32px;
				display: block;
				color: $normal;
				position: relative;
				&:hover{
					opacity: .64;
				}
				&:after{
					content: "\f061";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					font-size: 11px;
				}
            }
        }
    }
	
	
	//SINGLE
	.metabox{
        @include flex();
        align-items: center;
        justify-content: flex-start;
        font-size: 13px;
        .category{
            @include flex();
            align-items: center;
            justify-content: flex-start;
        }
        time,
        li{
            border: $normal 1px solid;
            padding: 2px 16px;
            display: inline-block;
            margin: 0 10px 0 0;
        }
        li{
            background: $glay;
            border-color: $glay;
        }

	}
}