@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;700&family=Oswald:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@600&display=swap');

@import "module/reset";
@import "module/module";

//USE ASSETS
@import "plugin/swiper";

/* BASE COLOR
------------------------------------------------------------*/
$normal:#121212;
$dark:#303043;
$link:#007333;
$green: #009844;
$glay: #f2f2f2;
$blue: #009fe8;
$red: #d22;
$border:#e8e8e8;


/* SITE SETTING
------------------------------------------------------------*/
$width: 1120px;
$tab: $width - 1px;
$phone: 640px;

$ease: cubic-bezier(0.85, 0, 0.15, 1);
$pc-head:72px;
$sp-head:60px;

@mixin osw(){
	font-family: 'Oswald', sans-serif;
	font-weight: 600;
}
@mixin notomin(){
	font-family: 'Noto Serif JP', serif;
	font-weight: 600;
}

@mixin fontsize($w: 32px, $m: 22){
    font-size: calc(#{$w} + #{$m} * (100vw - 450px)/ 900);
}


@media screen and (min-width: $width){
	@include default(
		$width, //サイト幅
		15px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		40px, //パディング
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $tab){
	@include default(
		auto, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
        30px, //パディング
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $phone){
	html{
	  height: -webkit-fill-available;
	}
	body{
	  min-height: 100vh;
	  min-height: -webkit-fill-available;
	}
	@include default(
		auto, //サイト幅
		13.5px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
        15px, //パディング
		#FFF //ボディ背景
	);
}

@import "common/header";
@import "common/footer";
@import "common/bread";
@import "page/index";
@import "page/firstview";
@import "page/company";
@import "page/business";
@import "page/contact";
@import "page/recruit";
@import "page/privacy";
@import "page/message";
@import "page/feature";
@import "page/csr";
@import "page/works";
@import "page/news";
@import "page/edit";



body{
	&.hidden{
		overflow-y: hidden;
	}
}
#site-wrapper,#site-footer{
}

.site-module{
	&.wrap{
		padding: 88px 0;
		@media screen and (max-width: $tab){
			padding: 56px 0;	
		}
		@media screen and (max-width: $phone){
			padding: 32px 0;	
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
		&.bg-glay{
			background: $glay;
		}
	}
	h2,h3,h4{
		&.large{
			font-size: 36px;
			font-weight: 700;
			letter-spacing: .08em;
			text-align: center;
			margin: 0 0 32px;
			line-height: 1;
			@media screen and (max-width: $tab){
				
			}
			@media screen and (max-width: $phone){
				font-size: 24px;	
			}
			&:after{
				font-size: 13px;
				content: attr(data-eng);
				display: block;
				margin: 14px 0 0;
			}
		}
	}
    h2,h3,h4{
        &.borders{
            border-bottom: rgba(#000,.1) 1px solid;
            position: relative;
            padding: 12px;
            padding-left: 20px;
            font-size: 24px;
            line-height: 1;
            font-weight: 700;
            margin: 0 0 24px;
			@media screen and (max-width: $phone){
				font-size: 17px;
				padding-left: 16px;
				margin: 0 0 16px;	
			}
			&.min{
				@include min();
				font-weight: 600;
			}
            &:after{
                content: '';
                width: 5px;
                height: 60%;
                background: $green;
                border-radius: 4px;
                display: block;
                position: absolute;
                top: 20%;
                left: 0;
				@media screen and (max-width: $phone){
					width: 4px;
					top: 25%;
				}
            }
        }
		small{
			display: inline-block;
			margin: 0 0 0 20px;
			font-size: 12px;
		}
    }
	.tablestyle{
		background: #FFF;
		width: 100%;
		border-collapse: collapse;
		@media screen and (max-width: $phone){
			display: block;
			border: $border 2px solid;
		}
		&.table-center{
			tbody{
				tr{
					th,td{
						text-align: center;
						@media screen and (max-width: $phone){
							text-align: left;	
						}
					}
				}
			}
		}
		tbody{
			@media screen and (max-width: $phone){
				display: block;
			}
			tr{
				border-bottom: $glay 2px solid;
				@media screen and (max-width: $phone){
					display: block;
					&:last-child{
						border: none;
					}
				}
				th,td{
					vertical-align: middle;
					text-align: left;
					padding: 32px;
					@media screen and (max-width: $phone){
						display: block;
						padding: 12px 15px;
					}
				}
				th{
					width: 240px;
					border-right: $glay 2px solid;
					@media screen and (max-width: $phone){
						width: auto;
						border: none;
						background: lighten($glay, 2%);
					}
				}
			}
		}
	}
	.formstyle{
		border: none;
		border-collapse: collapse;
		width: 100%;
		@media screen and (max-width: $phone){
			display: block;	
		}
		tbody{
			@media screen and (max-width: $phone){
				display: block;	
			}
			tr{
				border-bottom: $border 1px solid;
				@media screen and (max-width: $phone){
					display: block;	
				}
				th,
				td{
					padding: 24px 0;
					vertical-align: middle;
					@media screen and (max-width: $phone){
						display: block;
						padding: 15px 0;
					}
				}
				th{
					width: 240px;
					text-align: left;
					font-size: 16px;
					@media screen and (max-width: $phone){
						width: auto;
						font-size: 15px;
						padding-bottom: 0;
					}
					&.hiss{
						&:after{
							content: '必須項目';
							background: $green;
							color: #FFF;
							float: right;
							padding: 4px 12px;
							border-radius: 3px;
							font-size: 13px;
						}
					}
				}
				td{
					padding-left: 48px;
					@media screen and (max-width: $phone){
						padding-left: 0;	
					}
					input[type=text],
					input[type=tel],
					input[type=email],
					input[type=password],
					select,
					textarea{
						position: relative;
						z-index: 1;
						@include font();
						font-size: 15px;
						padding: 8px;
						background: lighten($glay, 2%);
						border: $border 2px solid;
						border-radius: 4px;
						display: block;
						width: 100%;
						outline: none;
						@include transition(160ms);
						@media screen and (max-width: $phone){
							-webkit-appearance: none;
						}
						&:focus{
							border-color: #FFF;
							background: #FFF;
							z-index: 2;
							box-shadow: 0 4px 24px rgba(#000,.12);
							transform: scale(1.02);
						}
					}
					textarea{
						min-width: 100%;
						max-width: 100%;
						min-height: 200px;
					}
				}
			}
		}
	}
	.btnarea{
		margin: 32px 0 0;
		@media screen and (max-width: $phone){
			margin: 24px 0 0;	
		}
		&.l{
			margin: 40px 0 0;
			@media screen and (max-width: $phone){
				margin: 32px 0 0;	
			}
		}
		&.center{
			text-align: center;
		}
		a{
			background: $green;
			display: inline-block;
			font-size: 17px;
			color: #FFF;
			padding: 13px 48px;
			min-width: 280px;
			text-align: center;
			position: relative;
			transition: transform 160ms $ease;
			@media screen and (max-width: $phone){
				display: block;
				font-size: 16px;
				padding: 12px 0;
			}
			&.arrow{
				position: relative;
				&:before{
					content: "\f054";
					font-weight: 900;
					font-family: "Font Awesome 5 Free";
					position: absolute;
					top: 50%;
					right: 15px;
					transform: translateY(-50%);
					font-size: 12px;
					transition: right 120ms;
				}
			}
			&.reclbtn{
				border: $green 2px solid;
				font-size: 20px;
				padding: 12px 72px;
				background: #FFF;
				color: $green;
				font-weight: 700;
				border-radius: 999px;
				@media screen and (max-width: $phone){
					font-size: 17px;
					padding: 10px 0;
				}
				i{
					display: inline-block;
					margin: 0 8px 0 0;
				}
				&:hover{
					color: #FFF;
					background: $green;
				}
			}
			&:hover{
				background: lighten($green, 4%);
				&.arrow{
					&:before{
						right: 12px;
					}
				}
			}
			.window{
				display: inline-block;
				margin: 0 0 0 8px;
				font-size: 14px;
			}
		}
	}
	.pager{
		@include flex();
		align-items: center;
		justify-content: center;
		margin: 32px 0 0;
		a,
		span{
			width: 36px;
			height: 36px;
			background: $glay;
			color: $normal;
			@include flex();
			align-items: center;
			justify-content: center;
			margin: 0 6px;
			@include transition(200ms,$ease:$ease);
		}
		a{
			&:hover{
				background: #FFF;
				box-shadow: inset 0 8px 32px rgba($normal,.24);
			}
		}
		span{
			color: #FFF;
			background: $green;
		}
	}
}

.site-404{
	.context{
		text-align: center;
	}
}

#map{
	position: relative;
    height: 460px;
    overflow: hidden;
	@media screen and (max-width: $phone){
		height: 200px;	
	}
	iframe{
        width: 100%;
        height: calc(460px + 400px);
        vertical-align: bottom;
        transform: translateY(-200px);
		@media screen and (max-width: $phone){
			height: calc(200px + 400px);
		}
	}
}

#side{
	position: fixed;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	z-index: 1000;
    background: $green;
    color: #FFF;
    -webkit-writing-mode: vertical-rl;
    -moz-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    white-space: nowrap;
    padding: 32px 10px;
	letter-spacing: .024em;
	font-size: 16px;
	transition: padding 200ms $ease ,background 200ms linear;
	@media screen and (max-width: $tab){
		display: none;
	}
	&:hover{
		padding-top: 40px;
		padding-bottom: 40px;
		background: $link;
	}
	i{
		display: inline-block;
		margin: 0 0 8px;
	}
}

.recruit-mod{
    position: relative;
    background: $dark;
    @include flex();
    align-items: center;
    justify-content: flex-end;
	@media screen and (max-width: $tab){
		display: block;	
	}
	@media screen and (max-width: $phone){
		padding-top: 0 !important;	
	}
    &__bg{
        width: 56%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center;
		@media screen and (max-width: $tab){
			width: 100%;
			height: 400px;
			position: relative;
		}
		@media screen and (max-width: $phone){
			height: auto;
			padding-top: 40%;
		}
    }
    &__content{
        width: 44%;
        padding: 0 40px;
		@media screen and (max-width: $tab){
			width: auto;
			padding: 32px 0 0;
			width: 80%;
			margin: 0 auto;
			text-align: center;
		}
		@media screen and (max-width: $phone){
			width: auto;
			padding: 24px 15px 0;
		}
        dl{
            max-width: 640px;
            padding: 0 40px;
            color: $border;
			@media screen and (max-width: $tab){
				max-width: 100%;
				padding: 0;
			}
            dt{
                font-size: 36px;
                line-height: 1;
                margin: 0 0 16px;
                padding: 0 0 20px;
                border-bottom: rgba(#FFF,.08) 1px solid;
				@media screen and (max-width: $phone){
					font-size: 28px;	
				}
                &:before{
                    content: attr(data-eng);
                    color: $green;
                    font-size: 12px;
                    letter-spacing: .08em;
                    margin: 0 0 6px;
                    display: block;
                }
            }
            dd{
                text-align: justify;
				@media screen and (max-width: $tab){
					text-align: center;	
				}
				@media screen and (max-width: $phone){
					text-align: justify;
				}
            }
        }
    }
}


#intro{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    transition: opacity 800ms;
	visibility: hidden;
    &.visible{
		visibility: visible;
	}
	&:before,
	&:after{
		transition: width 800ms 800ms $ease;
		z-index: 1;
	}
    &.active{
		&:before,
		&:after{
			width: 0;
		}
		.introtitle,
		.spinner{
			transition: opacity 800ms;
			opacity: 0 !important;
		}
    }
	.introtitle{
		position: absolute;
		top: calc(50% - 12px);
		left: calc(50% - 8px);
		transform: translateX(-50%) translateY(-50%);
		z-index: 2;
		svg{
			width: 360px;
			height: auto;
			@media screen and (max-width: $phone){
				width: 240px;	
			}
			path{
				stroke: $normal;
				fill: $normal;
				fill-opacity: 0;
				stroke-width:　1.5;
				stroke-dasharray: 6000;
				stroke-dashoffset: 6000;
				animation: lineanime 4s ease-in 800ms forwards;
			}
			@keyframes lineanime{
				0%{stroke-dashoffset: 6000; fill-opacity: 0;}
				30% {fill-opacity: 0;}
				35% {fill-opacity: 1;}
				100%{stroke-dashoffset:0; fill-opacity: 1;}
			}
		}
	}
	.spinner {
		text-align: center;
		position: absolute;
		left: 50%;
		top: calc(50% + 32px);
		transform: translateX(-50%) translateY(-50%);
		opacity: 0;
		z-index: 2;
		animation: fadeto 400ms linear 3s forwards;
		@keyframes fadeto{
			0%{
				opacity: 0;
			}
			100%{
				opacity: 1;
			}
		}
	}
	.spinner > div {
		width: 9px;
		height: 9px;
		margin: 0 4px;
		background-color: $green;
		border-radius: 100%;
		display: inline-block;
		-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
		animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	}
	.spinner .bounce1 {
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}
	.spinner .bounce2 {
		-webkit-animation-delay: -0.16s;
		animation-delay: -0.16s;
	}
	@-webkit-keyframes sk-bouncedelay {
		0%, 80%, 100% { -webkit-transform: scale(0) }
		40% { -webkit-transform: scale(1.0) }
	}
	@keyframes sk-bouncedelay {
		0%, 80%, 100% { 
			-webkit-transform: scale(0);
			transform: scale(0);
		}
		40% { 
			-webkit-transform: scale(1.0);
			transform: scale(1.0);
		}
	}
}

.scrollanime{
    $speed: 600ms;
    $delay: 80ms;
    &__view{
        opacity: 0;
        transform: translateY(40px); 
    }
    &._active{
        .scrollanime__view{
            @for $i from 1 through 100{
                &:nth-of-type(#{$i}){
                    opacity: 1;
                    transform: translateY(0);
                    transition: opacity $speed $delay*$i ease, transform $speed $delay*$i ease; 
                }
            }
        }
    }
}

.scroll-img{
	position: relative;
	$speed: 600ms;
	&:before,
	&:after{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
	&:before{
		background: $green;
		@include transition($speed , $delay:$speed , $ease:$ease);
		z-index: 2;
	}
	&:after{
		background: darken($glay, 8%);
		@include transition($speed , $ease: $ease);
		z-index: 3;
	}
	&.__active{
		&:before{
			width: 0;
		}
		&:after{
			width: 0;
		}
	}
}

.scrollfill{
    &__view{
        display: inline-block;
        overflow: hidden;
        position: relative;
        vertical-align: bottom;
        &:before,
        &:after{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        &:before{
            left: auto;
            right: 0;
            background: $normal;
            z-index: 2;
        }
        &:after{
            width: 0;
            background: $green;
            z-index: 3;
        }
        &--glay{
            &:before{
                background: $glay;
            }
        }
    }
    &._active{
        .scrollfill__view{
            @for $i from 1 through 10{
                &:nth-of-type(#{$i}){
                    &:before{
                        width: 0;
                        @include transition(300ms, 300ms*$i, $ease);
                    }
                    &:after{
                        animation: scrollfill 600ms 300ms*$i $ease forwards;
                        @keyframes scrollfill{
                            0%{
                                width: 0;
                                left: 0;
                            }
                            50%{
                                width: 100%;
                                left: 0;
                            }
                            100%{
                                width: 0%;
                                left: 100%;
                            }
                        }
                    }       
                }
            }
        }
    }
}

.fade-enter-active {
	transition: 600ms 600ms;
}
.fade-leave-active {
	transition: 600ms 0ms; 
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

.scale-enter-active, .scale-leave-active {
	transition: 400ms $ease;
}
.scale-enter, .scale-leave-to {
	opacity: 0;
}
