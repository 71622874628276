#site-header{
	background: #FFF;
	width: 100%;
	height: $pc-head;
	padding: 0 40px;
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	@include flex();
	align-items: center;
	transition: box-shadow 400ms;
	@media screen and (max-width: $tab){
		padding: 0 30px;	
	}
	@media screen and (max-width: $phone){
		height: $sp-head;
		padding: 0 15px;
	}
	&.active{
		box-shadow: 0 0 32px rgba($normal,.1);
	}
	.logo{
		width: 220px;
		@media screen and (max-width: $phone){
			width: 180px;	
		}
		img{
			width: 100%;
		}
	}
	.gnavi{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		@media screen and (min-width: $tab + 1px){
			display: block !important;	
		}
		@media screen and (max-width: $tab){
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: translateX(0) translateY(0);
			background: $glay;
			overflow: auto;
			visibility: hidden;
			z-index: 9999;
			&.active{
				visibility: visible;
			}
		}
		&__list{
			@include flex();
			align-items: center;
			transform: translateX(.7vw);
			@media screen and (max-width: $tab){
				display: block;
				transform: translateX(0);
				padding: 30px;
				padding-top: 78px;
			}
			@media screen and (max-width: $phone){
				padding: 15px;
				padding-top: 60px;
			}
			&__item{
				margin: 0 4vw 0 0;
				letter-spacing: .12em;
				@include flex();
				&.sp{
					display: none;
					@media screen and (max-width: $tab){
						display: block;	
					}
				}
				&.no-margin{
					margin-right: 0;
				}
				@media screen and (max-width: $tab){
					margin: 0;
					display: block;
					margin: 0 0 30px;
					background: #FFF;
					border-radius: 4px;
					box-shadow: 0 0 15px rgba(#000,.04);
					&:last-child{
						margin: 0;
					}
				}
				@media screen and (max-width: $phone){
					margin: 0 0 15px;	
				}
				&:last-child{
					margin: 0;
				}
			}
			a{
				color: $normal;
				&:hover{
					color: $green;
				}
			}
			&__item > a{
				position: relative;
                @media screen and (max-width: $tab){
                    font-size: 17px;
                    font-weight: 700;
					padding: 12px 15px;
					border-radius: 4px;
					display: block;
					position: relative;
					&:after{
						content: "\f054";
						font-weight: 900;
                        font-family: "Font Awesome 5 Free";
						position: absolute;
						top: 50%;
						right: 15px;
						transform: translateY(-50%);
						color: $green;
						font-size: 11px;
					}
					@media screen and (max-width: $phone){
						font-size: 16px;	
					}
                }
				&:before{
					content: '';
					width: 0;
					height: 3px;
					background: $green;
					position: absolute;
					left: 50%;
					bottom: -23px;
					transform: translateX(-50%);
					transition: width 200ms $ease;
					@media screen and (max-width: $tab){
						display: none;
					}
				}
				&:hover{
					&:before{
						width: 40px;
					}
				}
			}
			dl{
				position: relative;
				@media screen and (max-width: $tab){
					padding: 15px;
				}
				dt{
					position: relative;
					z-index: 2;
					@media screen and (max-width: $tab){
						font-size: 17px;
						font-weight: 700;
						margin: 0 0 15px;
						@media screen and (max-width: $phone){
							font-size: 16px;	
						}
						a{
							display: block;
							position: relative;
							&:after{
								content: "\f054";
								font-weight: 900;
								font-family: "Font Awesome 5 Free";
								position: absolute;
								top: 50%;
								right: 0;
								transform: translateY(-50%);
								color: $green;
								font-size: 11px;
							}
						}
					}
				}
				&:hover{
					@media screen and (min-width: $tab + 1px){
						&:before{
							content: '';
							width: 250%;
							height: $pc-head;
							position: absolute;
							top: 0px;
							left: 50%;
							transform: translateX(-50%);
							cursor: pointer;
							z-index: 1;
						}
						dd{
							opacity: 1;
							pointer-events: auto;
							visibility: visible;
							transform: translateX(-50%) translateY(0);
							&:after{
								width: 40px;
							}
						}
					}
				}
				dd{
					@media screen and (min-width: $tab + 1px){
						position: absolute;
						width: 240px;
						background: #FFF;
						top: 49px;
						left: 50%;
						transform: translateX(-50%) translateY(16px);
						text-align: center;
						pointer-events: none;
						visibility: hidden;
						opacity: 0;
						box-shadow: 0 16px 16px rgba(#000,.08);
						transition: opacity 200ms $ease, transform 200ms $ease;
					}
					@media screen and (max-width: $tab){
						line-height: 1.56;
					}
					&:after{
						content: '';
						width: 0;
						height: 3px;
						position: absolute;
						top: -3px;
						left: 50%;
						background: $green;
						transform: translateX(-50%);
						transition: width 200ms $ease;
						@media screen and (max-width: $tab){
							display: none;
						}
					}
					ul{
						@media screen and (max-width: $tab){
							@include flex();
							justify-content: flex-start;
							margin: 0 0 0 -15px;
						}
						@media screen and (max-width: $phone){
							margin: 0 0 0 -7.5px;	
						}
						li{
							border-bottom: $border 1px solid;
							&:last-child{
								border-bottom: none;
							}
							@media screen and (max-width: $tab){
								border: none;
								width: 33.3%;
								padding: 0 0 0 15px;
								margin: 15px 0 0;
								&:nth-child(-n+3){
									margin-top: 0;
								}
							}
							@media screen and (max-width: $phone){
								width: 50%;
								padding: 0 0 0 7.5px;
								margin: 0 0 7.5px;
								&:nth-last-child(-n+2){
									margin-bottom: 0;
								}
							}
							a{
								display: block;
								padding: 15px;
								@media screen and (max-width: $tab){
									padding: 15px;
									background: $glay;
									text-align: center;
								}
								@media screen and (max-width: $phone){
									padding: 10px 8px;
									font-size: 14px;
								}
							}
						}
					}
				}
			}
		}
	}
	.meta{
		@media screen and (max-width: $tab){
			display: none;
		}
		a{
			color: $normal;
			display: block;
			border: $border 2px solid;
			border-radius: 999px;
			padding: 6px 32px;
			@inclde transition(160ms);
			i{
				color: darken($border, 16%);
				display: inline-block;
				margin: 0 8px 0 0;
				font-size: 16px;
				@inclde transition(160ms);
			}
			&:hover{
				color: #FFF;
				background: $green;
				border-color: $green;
				box-shadow: 0 0 16px rgba($green,.24);
				i{
					color: #FFF;
				}
			}
		}
	}
	
	.openmenu,
	.closemenu{
		display: none;
		@media screen and (max-width: $tab){
			background: $green;
			display: inline-block;
			padding: 6px 24px;
			color: #FFF;
			border-radius: 999px;
			cursor: pointer;
			i{
				display: inline-block;
				margin: 0 8px 0 0;
			}
		}
		@media screen and (max-width: $phone){
			font-size: 14px;
			font-weight: 700;
			padding: 5px 20px;
		}
	}
	.closemenu{
		position: absolute;
		top: 18px;
		right: 30px;
		cursor: pointer;
		z-index: 3;
		@media screen and (max-width: $phone){
			top: 12px;
			right: 15px;	
		}
	}
}