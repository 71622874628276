.site-contact{
	.contactbox{
		background: $glay;
		padding: 20px 6%;
		border-radius: 4px;
		@include flex();
		@media screen and (max-width: $tab){
			padding: 20px;	
		}
		@media screen and (max-width: $phone){
			padding: 15px;
			display: block;
		}
		li{
			width: calc(50% - 12px);
			background: #FFF;
			border-radius: 4px;
			box-shadow: 0 0 20px rgba(#000,.02);
			text-align: center;
			padding: 24px;
			@media screen and (max-width: $phone){
				width: auto;
				padding: 15px;
				margin: 0 0 12px;
				&:last-child{
					margin: 0;
				}
			}
			dl{
				line-height: 1;
				dt{
					font-weight: 700;
					font-size: 20px;
					margin: 0 0 16px;
					@media screen and (max-width: $phone){
						font-size: 17px;	
					}
				}
				dd{
					margin: 12px 0 0;
				}
				.tel{
					font-size: 32px;
					font-weight: 700;
					color: $green;
					@media screen and (max-width: $phone){
						font-size: 24px;	
					}
					i{
						display: inline-block;
						margin: 0 6px 0 0;
						transform: rotateY(180deg) rotateZ(-10deg) translateY(-1px);
						font-size: .8em;
					}
				}
				.btn{
					display: block;
					width: 320px;
					margin: 0 auto;
					background: $green;
					color: #FFF;
					font-size: 18px;
					padding: 17px 0;
					border-radius: 4px;
					box-shadow: 0 6px 0 $link;
					@include transition(100ms);
					@media screen and (max-width: $tab){
						width: auto;
						display: block;
					}
					@media screen and (max-width: $phone){
						font-size: 17px;	
					}
					&:hover{
						transform: translateY(4px);
						box-shadow: 0 2px 0 $link;
					}
					i{
						display: inline-block;
						margin: 0 8px 0 0;
					}
				}
			}
		}
	}
	.privacy{
		text-align: center;
		margin: 32px 0 0;
		@include flex();
		align-items: center;
		font-size: 17px;
		justify-content: center;
		@media screen and (max-width: $phone){
			margin: 16px 0 0;
			font-size: 13px;
		}
		input{
			display: inline-block;
			border: $border 2px solid;
			border-radius: 6px;
			width: 22px;
			height: 22px;
			margin: 0 10px 0 0;
			transform: translateY(1px);
		}
		a{
			text-decoration: underline;
		}
	}
	.send{
		margin: 32px 0 0;
		text-align: center;
		input{
			@include font();
			width: 400px;
			height: 56px;
			background: $green;
			border: $green 2px solid;
			color: #FFF;
			font-size: 18px;
			font-weight: 700;
			border-radius: 4px;
			cursor: pointer;
			@media screen and (max-width: $phone){
				width: 100%;
			}
			&:hover{
				background: #FFF;
				color: $green;
			}
		}
	}
}