.site-privacy{
	.context{
		text-align: center;
		margin: 0 0 32px;
	}
	.site-module{
		.tablestyle{
			border: $border 1px solid;
			border-collapse: collapse;
			tbody{
				tr{
					th,td{
						border: $border 1px solid;
						@media screen and (max-width: $phone){
							border: none;
						}
					}
					th{
						background: lighten($glay, 3%);
						@media screen and (max-width: $phone){
							border-bottom: $border 1px solid;
						}
					}
				}
			}
		}
	}
}