.breadcrumb{
	margin: $pc-head 0 0;
	position: relative;
	background: $normal;
	color: #FFF;
	overflow: hidden;
	text-shadow: 0 0 16px #000, 0 0 16px rgba(#000,.64);
	@media screen and (max-width: $phone){
		margin: $sp-head 0 0;
	}
	&.active{
		.breadcrumb__bg{
			transform: scale(1);
			transition: transform 2000ms linear;
		}
		.breadcrumb__title{
			h1{
				$timer: 400ms;
				opacity: 1;
				&.min{
					@include notomin();
				}
                span{
                    @for $i from 1 through 20{
                        &:nth-of-type(#{$i}){
                            animation: toTitle-down 800ms $i*60ms+$timer forwards;
                        }
                    }
                    @keyframes toTitle-down{
                        0%{
                            transform: translateY(-10px);
                            opacity: 0;
                        }
                        100%{
                            transform: translateY(0);
                            opacity: 1;
                        }
                    }
                }
				&:after{
					@include font();
					transform: translateY(0);
					opacity: 1;
					transition: transform 800ms 800ms+$timer, opacity 800ms  800ms+$timer;
				}
			}
		}
	}
	.display{
		min-height: 360px;
		max-height: 440px;
		height: 23vw;
		@include flex();
		align-items: center;
		justify-content: flex-start;
		@media screen and (max-width: $tab){
			min-height: 1px;
			max-height: 100%;
			height: 280px;
		}
		@media screen and (max-width: $phone){
			height: 180px;	
		}
	}
	&__bg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		z-index: 1;
		transform: scale(1.04);
		opacity: .88;
	}
	&__title{
		position: relative;
		transform: translateY(8px);
		z-index: 2;
		margin: 0 0 0 15px;
		h1{
			font-size: 56px;
			font-weight: 500;
			line-height: 1;
			opacity: 0;
			@media screen and (max-width: $tab){
				font-size: 36px;	
			}
			@media screen and (max-width: $phone){
				font-size: 24px;	
			}
			span{
				opacity: 0;
				display: inline-block;
			}
			&:after{
				content: attr(data-eng);
				font-size: 18px;
				font-weight: 600;
				display: block;
				margin: 20px 0 0;
				letter-spacing: .12em;
				text-indent: .1em;
				opacity: 0;
				transform: translateY(20px);
				@media screen and (max-width: $phone){
					font-size: 12px;
					margin: 10px 0 0;
				}
			}
		}
	}
	&__nav{
		position: absolute;
		top: 16px;
		left: 40px;
		font-size: 13px;
		z-index: 2;
		@media screen and (max-width: $phone){
			top: 15px;
			left: 15px;
			font-size: 10px;
		}
		ol{
			@include flex();
			align-items: center;
			justify-content: flex-start;
			li{
				list-style: none;
				a{
					color: #FFF;
				}
				i{
					color: $green;
					display: inline-block;
					margin: 0 8px 0 0;
				}
				&:after{
					content: "\f054";
					font-weight: 900;
					font-family: "Font Awesome 5 Free";
					display: inline-block;
					margin: 0 16px;
					font-size: 10px;
					transform: translateY(-2px);
				}
				&:last-child{
					&:after{
						display: none;
					}
				}
			}
		}
	}
}