.site-index{
	.fv{
		width: 100%;
		height: 100vh;
		@include flex();
		align-items: center;
		justify-content: center;
		overflow: hidden;
		@media screen and (max-width: $tab){
			height: 640px;
			position: relative;
		}
		@media screen and (max-width: $phone){
			height: 100vh;
		}
		/*
		&:before{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: rgba(#333,.38);
		}
		*/
		&__catch{
			color: #FFF;
			font-weight: 700;
			line-height: 1;
			position: relative;
			text-align: center;
			pointer-events: none;
			transform: translateY(-4%);
			&__eng{
				@include osw();
				@include fontsize(10px,10);
				letter-spacing: .64em;
				margin: 0 0 18px;
				opacity: 0;
				span{
					display: inline-block;
					opacity: 0;
				}
			}
			h1{
				@include notomin();
				@include fontsize(64px,16);
				letter-spacing: .04em;
				padding: 0 0 40px;
				position: relative;
				opacity: 0;
				span{
					display: inline-block;
					opacity: 0;
				}
				&:before{
					content: '';
					width: 0;
					height: 3px;
					background: #FFF;
					position: absolute;
					bottom: 12px;
					left: 0;
					border-radius: 999px;
				}
			}
			&__txt{
				@include fontsize(11px,10);
				line-height: 1.6;
				font-weight: 500;
				letter-spacing: .08em;
				transform: translateY(10px);
				opacity: 0;
			}
		}
		&__news{
			position: absolute;
			left: 0;
			bottom: 0;
			background: $dark;
			padding: 22px 40px;
			padding-right: 32px;
			z-index: 2;
			color: $glay;
			opacity: 0;
			transform: translateX(-20px);
			@media screen and (max-width: $phone){
				padding: 12px 15px;
				width: 100%;
			}
			&:before{
				content: '';
				width: 64px;
				height: 100%;
				background: $dark;
				position: absolute;
				top: 0;
				right: -32px;
				transform: skewX(20deg);
				z-index: -1;
			}
			a{
				color: $glay;
				&:hover{
					h2{
						color: #FFF;
						text-decoration: underline;
					}
				}
			}
			&__list{
				font-size: 13.5px;
				width: 540px;
				@media screen and (max-width: $phone){
					width: auto;	
				}
				> i{
					font-size: 15px;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					opacity: .24;
					@media screen and (max-width: $phone){
						right: 15px;
						top: auto;
						bottom: 12px;
					}
				}
				&__item{
					@include flex();
					align-items: center;
					@media screen and (max-width: $phone){
						justify-content: flex-start;	
					}
					time,
					.cate{
						width: 100px;
						font-size: 12px;
						background: $green;
						text-align: center;
						padding: 4px 0;
						display: block;
						border-radius: 2px;
					}
					.cate{
						background: lighten($dark,8%);
						@media screen and (max-width: $phone){
							margin: 0 0 0 12px;	
						}
					}
					h2{
						width: calc(100% - 220px);
						padding: 0 0 0 10px;
						font-size: 14.5px;
						color: darken($glay, 8%);
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						@media screen and (max-width: $phone){
							font-size: 13px;
							width: calc(100% - 40px);
							padding: 8px 0 0;
						}
					}
				}
			}
		}
		&__scroll{
			position: absolute;
			right: 40px;
			bottom: 0;
			color: #FFF;
			-webkit-writing-mode: vertical-rl;
			-moz-writing-mode: vertical-rl;
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
			white-space: nowrap;
			z-index: 2;
			font-size: 13px;
			line-height: 1;
			letter-spacing: .24em;
			height: 120px;
			padding: 0 12px 0 0;
			@media screen and (max-width: $phone){
				display: none;
			}
			&:after{
				content: '';
				width: 2px;
				position: absolute;
				bottom: 0;
				right: 0;
				background: #FFF;
				height: 0;
				animation: sc 3000ms $ease infinite;
				@keyframes sc {
					0%{
						bottom: 100%;
						height: 0;
					}
					40%{
						bottom: 0;
						height: 100%;
					}
					80%{
						bottom: 0;
						height: 0;
					}
					100%{
						bottom: 0;
						height: 0;
					}
				}
			}
		}
		&__video{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			z-index: -1;
			@media screen and (max-width: $phone){
				top: $sp-head;
				height: calc(100% - #{$sp-head} - 80px);	
			}
		}
		#videos{
			display: block;
			@media screen and (max-width: $phone){
				display: none;
			}
		}
		#spvideos{
			display: none;
			@media screen and (max-width: $phone){
				display: block;	
			}
		}
		
		//Activate
		&.active{
			$timer: 800ms;
			$delay: 1200ms;
			.fv__catch{
				h1{
					opacity: 1;
					span{
						@for $i from 1 through 20{
							&:nth-of-type(#{$i}){
								animation: toShow 800ms $i*80ms+$timer forwards;
							}
						}
						@keyframes toShow{
							0%{
								transform: translateY(10px);
								opacity: 0;
							}
							100%{
								transform: translateY(0);
								opacity: 1;
							}
						}
					}
					&:before{
						animation: fvcatchh1before $delay/2 $timer $ease forwards;
						@keyframes fvcatchh1before{
							0%{
								width: 0;
							}
							100%{
								width: 100%;
							}
						}
					}
				}
				.fv__catch__eng{
					opacity: 1;
					span{
						@for $i from 1 through 20{
							&:nth-of-type(#{$i}){
								animation: toShow-down 800ms $i*60ms+$timer forwards;
							}
						}
						@keyframes toShow-down{
							0%{
								transform: translateY(-10px);
								opacity: 0;
							}
							100%{
								transform: translateY(0);
								opacity: 1;
							}
						}
					}
				}
				.fv__catch__txt{
					animation: fvcatchtxt 1200ms 1600ms+$timer forwards;
					@keyframes fvcatchtxt{
						0%{
							opacity: 0;
							transform: translateY(10px);
						}
						100%{
							opacity: 1;
							transform: translateY(0);
						}
					}
				}
			}
			.fv__news{
				opacity: 1;
				transform: translateX(0);
				transition: opacity 1200ms $timer, transform 1200ms $timer;
			}
		}

	}
}