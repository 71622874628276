.site-edit{
	margin: 16px 0 0;
    font-size: 15px;
    line-height: 1.8;
	@media screen and (max-width: $phone){
		margin: 15px 0 0;
		font-size: 14px;
	}
    h3{
        font-size: 24px;
        font-weight: 700;
        border-top: $normal 2px solid;
        border-bottom: $normal 2px solid;
        font-weight: 700;
        padding: 8px 2px;
        margin: 0 0 20px;
		@media screen and (max-width: $phone){
			font-size: 20px;
			margin: 0 0 10px;
		}
    }
    h4{
        font-size: 22px;
        border-bottom: $border 2px solid;
        font-weight: 700;
        padding: 0 0 0 16px;
        margin: 0 0 16px;
        position: relative;
		@media screen and (max-width: $phone){
			font-size: 18px;
			margin: 0 0 8px;
		}
        &:before{
            content: '';
            width: 4px;
            height: 80%;
            position: absolute;
            top: 10%;
            left: 0;
            background: $green;
            border-radius: 2px;
        }
    }
    h5{
        font-size: 20px;
        font-weight: 700;
        background: $glay;
        padding: 4px 15px;
        margin: 0 0 16px;
		@media screen and (max-width: $phone){
			font-size: 16px;	
		}
    }
    h6{
        position: relative;
        font-size: 18px;
        font-weight: 700;
        padding: 0 0 0 18px;
        margin: 0 0 8px;
		@media screen and (max-width: $phone){
			font-size: 14px;	
		}
        &:before{
            content: '';
            width: 12px;
            height: 12px;
            border: $green 4px solid;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
    table{
        border: $border 1px solid;
        border-collapse: collapse;
        width: 100%;
        margin: 0 0 40px;
		@media screen and (max-width: $phone){
			margin: 0 0 24px;	
		}
        tbody,
        thead{
            tr{
                td,th{
                    padding: 15px;
                    text-align: left;
                    vertical-align: middle;
                    border: $border 1px solid;
                }
                th{
                    background: $glay;
					@media screen and (max-width: $phone){
						width: 120px;	
					}
				}
            }
        }
    }
    img{
        max-width: 100%;
    }
    .avatar{
        width: 88px;
        margin: 0 15px;
        img{
            width: 100%;
            border-radius: 50%;
        }
        figcaption{
            text-align: center;
            margin: 15px 0 0;
            font-size: 14px;
        }
    }
    iframe{
        width: 100%;
        height: 300px;
        vertical-align: bottom;
        margin: 0 0 40px;
		@media screen and (max-width: $phone){
			margin: 0 0 24px;	
		}
    }
    p,
    ul,
    ol{
        margin: 0 0 40px;
		@media screen and (max-width: $phone){
			margin: 0 0 24px;	
		}
    }
	
}