#site-footer{
	background: $dark;
	color: $glay;
	a{
		color: $glay;
	}
	.pagetop{
		background: lighten($dark, 4%);
		color: rgba(#FFF,.64);
		text-align: center;
		padding: 16px 0;
		display: block;
		&:hover{
			background: lighten($dark, 6%);
			color: #FFF;
		}
	}
	
	.company{
		@include flex();
		align-items: center;
		justify-content: flex-start;
		margin: 64px 0;
		position: relative;
		@media screen and (max-width: $tab){
			margin: 40px 0;	
		}
		@media screen and (max-width: $phone){
			display: block;
			margin: 32px 0;
		}
		&__logo{
			width: 200px;
			margin: 0 24px 0 0;
			padding: 0 24px 0 0;
			border-right: rgba(#FFF,.16) 1px solid;
			@media screen and (max-width: $phone){
				width: 160px;
				margin: 0 auto;
				padding: 0;
				border: none;
			}
			img{
				width: 100%;
			}
		}
		&__title{
			margin: 0 24px 0 0;
			@media screen and (max-width: $phone){
				text-align: center;
				margin: 12px 15px 0;
			}
			dt{
				font-size: 20px;
				font-weight: 700;
				line-height: 1;
				margin: 0 0 5px;
				@media screen and (max-width: $phone){
					font-size: 16px;	
				}
			}
			dd{
				font-size: 10px;
			}
		}
		&__meta{
			font-size: 14px;
			@media screen and (max-width: $phone){
				text-align: center;
				margin: 8px 30px;
			}
			li{
				margin: 0 20px 0 0;
				@media screen and (max-width: $phone){
					margin: 0;	
				}
				&:last-child{
					margin: 0;
				}
			}
		}
		&__iso{
			width: 120px;
			position: absolute;
			top: 0;
			right: 0;
			@media screen and (max-width: $phone){
				position: relative;
				width: 100px;
				margin: 20px auto 0;
			}
			img{
				width: 100%;
			}
		}
	}
	
	.fnav{
		@include flex();
		padding: 0 0 64px;
		margin: 0 0 0 -40px;
		@media screen and (max-width: $tab){
			padding: 0 0 40px;
			margin: 0 0 30px -30px;
			justify-content: flex-start;
		}
		@media screen and (max-width: $phone){
			margin: 0;
			padding: 0 0 32px;
			display: block;
		}
		> li{
			width: 16.6%;
			padding: 0 0 0 40px;
			@media screen and (max-width: $tab){
				width: 33.3%;
				padding: 0 0 0 30px;
				margin: 30px 0 0;
				&:nth-child(-n+3){
					margin-top: 0;
				}
			}
			@media screen and (max-width: $phone){
				width: auto;
				padding: 0;
				margin: 0 0 15px;
				border: rgba(#FFF,.12) 1px solid;
				border-radius: 4px;
				&:last-child{
					margin: 0;
				}
			}
			> dl{
				dt{
					font-size: 15px;
					font-weight: 700;
					padding: 0 0 20px;
					position: relative;
					text-indent: .1em;
					@media screen and (max-width: $phone){
						padding: 0;
						background: rgba(#FFF,.08);
						font-size: 14px;
					}
					&:before,
					&:after{
						content: '';
						width: 100%;
						height: 1px;
						position: absolute;
						left: 0;
						bottom: 8px;
						background: rgba(#FFF,.1);
						@media screen and (max-width: $phone){
							display: none;
						}
					}
					&:after{
						background: $green;
						width: 32px;
					}
				}
				dd{
					font-size: 13px;
					@media screen and (max-width: $phone){
						ul{
							li{
								border-top: rgba(#FFF,.12) 1px solid;
							}
						}	
					}
				}
				@media screen and (max-width: $phone){
					a{
						padding: 15px;
						display: block;
						position: relative;
						&:after{
							content: "\f054";
							font-weight: 900;
							font-family: "Font Awesome 5 Free";
							position: absolute;
							top: 50%;
							right: 15px;
							transform: translateY(-50%);
							color: $green;
							font-size: 9px;
						}
					}
				}
			}
		}
	}
	
	.copy{
		display: block;
		padding: 32px 0;
		text-align: center;
		font-size: 10px;
		background: darken($dark, 4%);
		color: rgba(#FFF,.72);
	}
}