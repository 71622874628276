.site-csr{
	.context{
		font-size: 18px;
		text-align: center;
		font-weight: 700;
		transform: translateY(-8px);
		margin: 0 0 20px;
		@media screen and (max-width: $tab){
			font-size: 16px;
			width: 80%;
			margin: 0 auto 20px;
		}
		@media screen and (max-width: $phone){
			width: auto;
			font-size: 14px;
			text-align: justify;
		}
	}
	.list{
		padding: 40px;
		background: $glay;
		@media screen and (max-width: $tab){
			padding: 24px;	
		}
		@media screen and (max-width: $phone){
			padding: 15px;	
		}
		li{
			padding: 20px;
			background: #FFF;
			margin: 0 0 16px;
			font-size: 16px;
			box-shadow: 0 0 16px rgba(#000,.02);
			@media screen and (max-width: $phone){
				padding: 15px;
				font-size: 14px;
			}
			&:before{
				content: '■';
				color: $green;
				display: inline-block;
				margin: 0 8px 0 0;
			}
			&:last-child{
				margin: 0;
			}
		}
	}
}