.site-business{
	.business{
		position: relative;
		&__list{
			&__item{
				margin: 0 0 80px;
				@include flex();
				align-items: center;
				background: #FFF;
				@media screen and (max-width: $tab){
					margin: 0 0 64px;
					display: block;
				}
				@media screen and (max-width: $phone){
					margin: 0 0 32px;	
				}
				&:last-child{
					margin: 0;
				}
				&.rvs{
					.business__list__item__pic{
						order: 2;
					}
					.business__list__item__data{
						order: 1;
						.business__list__item__data__box{
							padding: 0 0 0 48px;
							@media screen and (max-width: $tab){
								padding: 0;	
							}
						}
					}
				}
				&__pic{
					background-size: cover;
					background-position: center;
					width: 56%;
					height: 480px;
					position: relative;
					overflow: hidden;
					@media screen and (max-width: $tab){
						width: auto;
						height: auto;
						padding-top: 50%;
						margin: 0 30px;
					}
					@media screen and (max-width: $phone){
						margin: 0 15px;	
					}
					img{
						display: none;
					}
					video{
						position: absolute;
						width: auto;
						height: auto;
						top: 50%;
						left: 50%;
						min-height: 100%;
						min-width: 100%;
						-ms-transform: translateX(-50%) translateY(-50%);
						-moz-transform: translateX(-50%) translateY(-50%);
						-webkit-transform: translateX(-50%) translateY(-50%);
						transform: translateX(-50%) translateY(-50%);
					}
				}
				&__data{
					padding: 0 48px;
					width: 44%;
					@media screen and (max-width: $tab){
						padding: 0;
						margin: 32px auto 0;
						width: 80%;
						text-align: center;
					}
					@media screen and (max-width: $phone){
						margin: 15px 15px 0;
						width: auto;
					}
					&__box{
						padding: 0 48px 0 0;
						@media screen and (max-width: $tab){
							padding: 0;	
						}
						h3{
							font-size: 32px;
							font-weight: 700;
							line-height: 1.5;
							margin: 0 0 20px;
							@media screen and (max-width: $phone){
								font-size: 26px;
								margin: 0 0 12px;
							}
							&:after{
								content: attr(data-eng);
								font-size: 13px;
								color: #009844;
								display: block;
								margin: 2px 0 0;
								@media screen and (max-width: $phone){
									font-size: 11px;	
								}
							}
						}
						p{
							font-size: 15px;
							text-align: justify;
							@media screen and (max-width: $phone){
								font-size: 14px;	
							}
							strong{
								font-size: 18px;
								font-weight: 700;
								@media screen and (max-width: $tab){
									display: block;
									text-align: center;
								}
							}
							&.sml{
								font-size: 12px;
								margin: 12px 0 0;
							}
						}
						dl{
							background: $glay;
							padding: 15px 20px;
							position: relative;
							border-radius: 3px;
							margin: 20px 0 0;
							@media screen and (max-width: $phone){
								text-align: left;	
							}
							dt{
								color: $green;
								font-weight: 700;
								@media screen and (max-width: $phone){
									font-size: 16px;
									font-weight: 700;
								}
							}
						}
					}
				}
			}
		}

	}

	//販売事業
	&.sinserity{
		.servicettl{
			text-align: center;
			margin: 0 0 40px;
			@media screen and (max-width: $phone){
				margin: 0 0 24px;	
			}
			h2{
				font-weight: 700;
				font-size: 32px;
				line-height: 1;
				margin: 0 0 24px;
				@media screen and (max-width: $phone){
					font-size: 20px;
					margin: 0 0 8px;
				}
			}
			&__txt{
				width: 80%;
				margin: 0 auto;
				text-align: justify;
				font-size: 16.5px;
				@media screen and (max-width: $phone){
					width: auto;
					font-size: 14px;
				}
			}
		}
		
		.itemlist{
			@include flex();
			justify-content: flex-start;
			margin: 0 0 80px;
			@media screen and (max-width: $tab){
				margin: 0 0 32px;	
			}
			@media screen and (max-width: $phone){
				display: block;	
			}
			li{
				width: 31%;
				margin: 3% 3% 0 0;
				background: $glay;
				position: relative;
				@media screen and (min-width: $tab + 1px){
					&:nth-child(3n+3){
						margin-right: 0;
					}
					&:nth-child(-n+3){
						margin-top: 0;
					}
				}
				@media screen and (max-width: $tab){
					width: 48.5%;
					&:nth-child(2n+2){
						margin-right: 0;
					}
					&:nth-child(-n+2){
						margin-top: 0;
					}
				}
				@media screen and (max-width: $phone){
					width: auto;
					margin: 0 0 15px;
					&:last-child{
						margin: 0;
					}
				}
				@for $i from 1 through 10{
					&:nth-child(#{$i}){
						&:before{
							content: '0#{$i}';
							position: absolute;
							top: 0;
							left: 0;
							width: 40px;
							height: 40px;
							background: $green;
							color: #FFF;
							font-size: 18px;
							@include flex();
							align-items: center;
							justify-content: center;
						}
					}
				}
				figure{
					img{
						width: 100%;
					}
				}
				dl{
					padding: 24px;
					dt{
						font-weight: 700;
						font-size: 18px;
						text-align: center;
						padding: 0 0 12px;
						margin: 0 0 12px;
						border-bottom: #FFF 2px solid;
					}
					dd{
						font-size: 14px;
						text-align: justify;
						@media screen and (max-width: $phone){
							font-size: 13.5px;	
						}
					}
				}
			}
		}
		h4{
			border-top: $normal 3px solid;
			border-bottom: $normal 3px solid;
			font-size: 32px;
			padding: 10px 0;
			text-align: center;
			margin: 0 0 48px;
			position: relative;
			@media screen and (max-width: $phone){
				font-size: 18px;	
			}
			&:after{
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 16px 12px 0 12px;
				border-color: $normal transparent transparent transparent;
				position: absolute;
				left: 50%;
				bottom: -16px;
				transform: translateX(-50%);
			}
		}
		.clums{
			@include flex();
			@media screen and (max-width: $phone){
				display: block;
			}
			figure{
				width: 40%;
				@media screen and (max-width: $phone){
					width: auto;	
				}
				img{
					width: 100%;
				}
			}
			dl{
				width: 60%;
				padding: 0 0 0 48px;
				@media screen and (max-width: $phone){
					width: auto;
					margin: 15px 0 0;
					padding: 0;
				}
				dt{
					font-size: 32px;
					font-weight: 700;
					margin: 0 0 16px;
					padding: 0 0 12px;
					border-bottom: $border 1px solid;
					@media screen and (max-width: $phone){
						font-size: 24px;	
					}
					&:after{
						content: attr(data-eng);
						color: $green;
						font-size: 13px;
						display: inline-block;
						margin: 0 0 0 8px;
					}
				}
				dd{
					text-align: justify;
					font-size: 16px;
					@media screen and (max-width: $phone){
						font-size: 14px;	
					}
				}
			}
		}
	}
	
	//鉄筋事業
	.bar{
		&__clum{
			@include flex();
			justify-content: flex-start;
			position: relative;
			margin: 0 0 64px;
			@media screen and (max-width: $tab){
				display: block;
				margin: 0 30px 64px;
			}
			@media screen and (max-width: $phone){
				margin: 0 15px 32px;	
			}
			&:last-child{
				margin: 0;
				@media screen and (max-width: $tab){
					margin: 0 30px;	
				}
				@media screen and (max-width: $phone){
					margin: 0 15px;	
				}
			}
			&--right{
				justify-content: flex-end;
			}
			&__bg{
				width: 58%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-color: $glay;
				background-size: cover;
				background-repeat: no-repeat;
				@media screen and (max-width: $tab){
					width: auto;
					height: 360px;
					position: relative;
				}
				@media screen and (max-width: $phone){
					height: 200px;	
				}
				&--large{
					width: 63%;
					@media screen and (max-width: $tab){
						width: auto;	
					}
				}
				&--right{
					left: auto;
					right: 0;
				}
				&--fade{
					&:before{
						content: '';
						width: 40%;
						height: 100%;
						position: absolute;
						top: 0;
						right: 0;
						background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
						background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
						background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
						@media screen and (max-width: $tab){
							display: none;
						}
					}
				}
				&--conner{
					background-position: right center;
					overflow: hidden;
					&:before,
					&:after{
						content: '';
						width: 30%;
						height: 100%;
						position: absolute;
						top: 0;
						left: -15%;
						background: #FFF;
						transform: skewX(-15deg) scale(1.1);
						opacity: .64;
						@media screen and (max-width: $tab){
							display: none;
						}
					}
					&:after{
						left: -20%;
						opacity: 1;
					}
				}
				&--containe{
					background-size: contain;
					background-color: #FFF;
					background-position: center;
				}
			}
			&__data{
				width: 42%;
				padding: 6vw 40px 7vw;
				position: relative;
				z-index: 2;
				@media screen and (max-width: $tab){
					width: 80%;
					margin: 32px auto 0;
					padding: 0;
				}
				@media screen and (max-width: $phone){
					width: auto;
					margin: 15px 0 0;
				}
				&__box{
					max-width: 620px;
					padding: 0 40px 0 0;
					margin: 0 auto;
					@media screen and (max-width: $tab){
						max-width: 100%;
						padding: 0;	
					}
					&--right{
						padding: 0 0 0 40px;
						@media screen and (max-width: $tab){
							padding: 0;	
						}
					}
					h2{
						font-size: 32px;
						font-weight: 700;
						margin: 0 0 18px;
						padding: 0 0 10px;
						border-bottom: $border 1px solid;
						@media screen and (max-width: $phone){
							font-size: 20px;
							margin: 0 0 12px;
							padding: 0 0 8px;
						}
						&:after{
							content: attr(data-eng);
							font-size: 13px;
							color: $green;
							display: inline-block;
							margin: 0 0 0 20px;
							@media screen and (max-width: $phone){
								font-size: 12px;	
							}
						}
					}
					ul{
						background: $glay;
						padding: 20px;
						border-radius: 4px;
						li{
							list-style: none;
							position: relative;
							padding: 0 0 0 24px;
							margin: 0 0 8px;
							&:last-child{
								margin: 0;
							}
							&:before{
								content: '';
								width: 12px;
								height: 12px;
								background: $green;
								position: absolute;
								top: 9px;
								left: 0;
								border-radius: 3px;
							}
						}
					}
					p.mb{
						margin-bottom: 20px;
					}
				}
			}
		}
	}
	
	//エコウェルメッシュ
	&.eco{
		.ecottl{
			text-align: center;
			margin: 0 0 32px;
			@media screen and (max-width: $phone){
				margin: 0 0 24px;	
			}
			h2{
				font-weight: 700;
				font-size: 32px;
				line-height: 1.5;
				margin: 0 0 24px;
				@media screen and (max-width: $tab){
					font-size: 28px;
				}
				@media screen and (max-width: $phone){
					font-size: 18px;
					margin: 0 0 12px;
				}
			}
			&__txt{
				width: 80%;
				margin: 0 auto;
				font-size: 16.5px;
				@media screen and (max-width: $tab){
					font-size: 15px;	
				}
				@media screen and (max-width: $phone){
					width: auto;
					margin: 0 15px;
				}
			}
		}
		.ecomes{
			background: $glay;
			padding: 32px;
			text-align: center;
			font-size: 16.5px;
			margin: 0 0 24px;
			@media screen and (max-width: $tab){
				font-size: 15px;	
			}
			@media screen and (max-width: $phone){
				font-size: 14px;
				padding: 15px;
				text-align: left;
			}
		}
		.kugin{
			@include flex();
			align-items: center;
			justify-content: center;
			@media screen and (max-width: $phone){
				display: block;
				padding: 0 0 32px;
				border-bottom: $border 1px solid;
			}
			.large{
				img{
					display: inline-block;
					transform: scale(1.4);
					@media screen and (max-width: $phone){
						transform: scale(1);
					}
				}
			}
			li{
				margin: 0 32px 0 0;
				@media screen and (max-width: $phone){
					margin: 0 0 8px;
					@include flex();
					justify-content: center;
					&:last-child{
						margin: 0;
					}
				}
				&:last-child{
					margin: 0;
					@media screen and (max-width: $phone){
						figure{
							img{
								height: 24px;
							}
						}	
					}
				}
				figure{
					@include flex();
					align-items: center;
					@media screen and (max-width: $phone){
						display: block;
						text-align: center;
					}
					img{
						height: 24px;
						@media screen and (max-width: $phone){
							height: 48px;	
						}
					}
					figcaption{
						font-size: 15px;
						padding: 0 0 0 24px;
						@media screen and (max-width: $phone){
							font-size: 12px;
							padding: 12px 0 0;
						}
					}
				}
			}
		}
		.videoclum{
			@include flex();
			align-items: center;
			@media screen and (max-width: $tab){
				display: block;	
			}
			&__vd{
				width: 600px;
				@media screen and (max-width: $tab){
					width: auto;	
				}
				video{
					width: 100%;
					height: 400px;
					background: #000;
					@media screen and (max-width: $tab){
						height: calc(100vw / 16 * 9 - 60px);	
					}
					@media screen and (max-width: $phone){
						height: calc(100vw / 16 * 9);
					}
				}
			}
			&__list{
				width: calc(100% - 600px);
				padding: 0 0 0 48px;
				@media screen and (max-width: $tab){
					margin: 32px 0 0;
					padding: 0;
					width: auto;
					@include flex();
				}
				@media screen and (max-width: $phone){
					margin: 15px 0 0;
					display: block;
				}
				li{
					background: $glay;
					font-size: 17px;
					padding: 15px;
					padding-left: 64px;
					margin: 0 0 16px;
					border-radius: 6px;
					position: relative;
					@media screen and (max-width: $tab){
						width: 32%;	
					}
					@media screen and (max-width: $phone){
						width: auto;
						margin: 0 0 8px;
						font-size: 15px;
						&:last-child{
							margin: 0;
						}
					}
					&:before{
						width: 32px;
						height: 32px;
						border-radius: 50%;
						color: #FFF;
						@include flex();
						align-items: center;
						justify-content: center;
						background: $green;
						color: #FFF;
						position: absolute;
						top: 50%;
						left: 15px;
						transform: translateY(-50%);
					}
					&:last-child{
						margin: 0;
					}
					@for $i from 1 through 10{
						&:nth-child(#{$i}){
							&:before{
								content: '#{$i}';
							}
						}
					}
				}
			}
		}
		.list{
			@include flex();
			justify-content: flex-start;
			margin: 0 0 0 -24px;
			@media screen and (max-width: $phone){
				display: block;
				margin: 0;
			}
			&__item{
				width: 33.3%;
				padding: 0 0 0 24px;
				margin: 24px 0 0;
				&:nth-child(-n+3){
					margin-top: 0;
				}
				@media screen and (max-width: $phone){
					width: auto;
					padding: 0;
					margin: 0 0 16px;
					&:last-child{
						margin: 0;
					}
				}
				@include flex();
				@for $i from 1 through 10{
					&:nth-child(#{$i}){
						.list__item__box{
							&:after{
								@include osw();
								content: '0#{$i}';
								position: absolute;
								top: 10px;
								left: 8px;
								color: #FFF;
								font-size: 18px;
								line-height: 1;
							}
						}
					}
				}
				&__box{
					background: $glay;
					height: auto;
					padding: 24px;
					padding-top: 32px;
					border-radius: 6px;
					position: relative;
					overflow: hidden;
					&:before{
						content: '';
						width: 100px;
						height: 100px;
						background: $green;
						position: absolute;
						top: 0;
						left: 0;
						transform: translateX(-50%) translateY(-50%) rotate(45deg);
					}
					figure{
						width: 128px;
						height: 128px;
						background: #FFF;
						border-radius: 50%;
						margin: 0 auto;
						@include flex();
						align-items: center;
						justify-content: center;
						img{
							width: 50%;
							opacity: .88;
						}
					}
					dl{
						padding: 24px 0 0;
						dt{
							text-align: center;
							font-size: 20px;
							color: $green;
							font-weight: 700;
							margin: 0 0 8px;
						}
						dd{
							font-size: 14px;
							text-align: justify;
						}
					}
				}
			}
		}
		.swiperbox{
			position: relative;
			.eco-button-next,
			.eco-button-prev{
				position: absolute;
				top: auto;
				bottom: -12px;
				left: 0;
				font-size: 20px;
				color: darken($border, 8%);
				cursor: pointer;
				&:hover{
					color: $green;
				}
			}
			.eco-button-next{
				left: auto;
				right: 0;
			}
			.eco-pagination{
				margin: 24px 0 0;
				@include flex();
				align-items: center;
				justify-content: center;
				.swiper-pagination-bullet{
					width: 9px;
					height: 9px;
					margin: 0 5px;
					background: darken($border, 8%);
					opacity: 1;
					transition: transform 80ms $ease;
					&:hover{
						transform: scale(1.4);
					}
					&.swiper-pagination-bullet-active{
						background: $green;
					}
				}
			}
		}
		.ecoswipe{
			.swiper-wrapper{
				transition-timing-function: $ease;
			}
			&__item{
				width: 32%;
				@media screen and (max-width: $phone){
					width: auto;	
				}
				figure{
					img{
						width: 100%;
					}
				}
			}
		}
	}
	.swipelay{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 9999;
		background: rgba(#000,.8);
		visibility: hidden;
		&.active{
			visibility: visible;
		}
		&__close{
			position: absolute;
			top: 32px;
			right: 32px;
			background: #FFF;
			padding: 8px 24px;
			border-radius: 999px;
			cursor: pointer;
			@media screen and (max-width: $phone){
				top: 15px;
				right: 15px;
			}
			i{
				display: inline-block;
				margin: 0 8px 0 0;
				color: $green;
			}
			&:hover{
				background: $green;
				color: #FFF;
				i{
					color: #FFF;
				}
			}
		}
		&__box{
			figure{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
				img{
					max-width: 65vw;
					max-height: 65vh;
					width: auto;
					height: auto;
					@media screen and (max-width: $phone){
						max-width: 80vw;
						max-height: 80vw;
					}
				}
				figcaption{
					background: #FFF;
					padding: 12px 15px;
				}
			}
		}
	}
}