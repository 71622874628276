.site-company{
	.first{
		position: relative;
		.ceobnr{
			display: none;
			@media screen and (max-width: $phone){
				display: block;
				img{
					width: 100%;
				}
			}
		}
		.ceo{
			display: block;
			background: $glay;
			padding: 8px;
			overflow: hidden;
			margin: 0 auto;
			@media screen and (max-width: $phone){
				display: none;
			}
			&:hover{
				img{
					opacity: 1;
				}
				.ceo__box__title__more{
					background: $green;
				}
			}
			&__box {
				position: relative;
				color: $normal;
				@include flex();
				align-items: center;
				justify-content: flex-end;
				&:before{
					content: '';
					position: absolute;
					top: 0;
					left: 8%;
					width: 50%;
					height: 100%;
					background: $glay;
					transform: skewX(-30deg) translateX(-10%);
					z-index: 2;
					@media screen and (max-width: $tab){
						width: calc(100% - 440px);
						left: 20%;
					}
				}
				&:after{
					content: '';
					width: calc(100% - 16px);
					height: calc(100% - 16px);
					position: absolute;
					top: 8px;
					left: 8px;
					border: $normal 1px solid;
					z-index: 3;
				}
				img{
					width: 620px;
					@media screen and (max-width: $tab){
						width: 440px;	
					}
				}
				&__title{
					@include notomin();
					position: absolute;
					top: 50%;
					left: 8%;
					transform: translateY(-50%);
					line-height: 1;
					z-index: 3;
					dt{
						font-size: 36px;
						letter-spacing: .024em;
						position: relative;
						padding: 0 56px 36px 0;
						@media screen and (max-width: $tab){
							font-size: 24px;	
						}
						&:before {
							content: '';
							width: 130%;
							height: 1px;
							background: $normal;
							position: absolute;
							left: 0;
							bottom: 16px;
						}
					}
					&__name{
						font-size: 20px;
						letter-spacing: .08em;
						@media screen and (max-width: $tab){
							font-size: 16px;	
						}
						strong{
							font-size: 36px;
							display: inline-block;
							margin: 0 0 0 16px;
							@media screen and (max-width: $tab){
								font-size: 28px;	
							}
						}
					}
					&__more{
						@include font();
						font-size: 15px;
						margin: 32px 0 0;
						display: inline-block;
						background: $normal;
						padding: 14px 32px;
						color: #FFF;
						i{
							display: inline-block;
							margin: 0 0 0 8px;
							font-size: 13px;
						}
					}
				}
			}
		}
		.slogan{
			text-align: center;
			@include notomin();
			line-height: 1;
			background: #FFF;
			background-size: 800px;
			padding: 48px;
			padding-bottom: 72px;
			@media screen and (max-width: $phone){
				padding: 24px;	
			}
			&__date{
				font-size: 20px;
				font-weight: 500;
				margin: 0 0 28px;
				letter-spacing: .08em;
				position: relative;
				@media screen and (max-width: $phone){
					font-size: 18px;
					margin: 0 0 16px;
				}
				.inlineb{
					display: inline-block;
					padding: 0 48px;
					position: relative;
					@media screen and (max-width: $phone){
						padding: 0 32px;	
					}
					&:before,
					&:after{
						content: '';
						width: 32px;
						height: 1px;
						background: $normal;
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
						@media screen and (max-width: $phone){
							width: 24px;	
						}
					}
					&:after{
						left: auto;
						right: 0;
					}
				}
			}
			h2{
				font-size: 88px;
				margin: 0 0 32px;
				display: inline-block;
				@media screen and (max-width: $phone){
					font-size: 48px;
					margin: 0 0 16px;
				}
				&:before,
				&:after{
					display: inline-block;
					opacity: .16;
				}
				&:before{
					content: '“';
					margin: 0 8px 0 0;
				}
				&:after{
					content: '”';
					margin: 0 0 0 8px;
				}
			}
			&__txt{
				font-size: 22px;
				@media screen and (max-width: $phone){
					font-size: 14px;	
				}
			}
		}
	}
	
	.company{
		font-size: 15.5px;
		@media screen and (max-width: $phone){
			font-size: 14px;	
		}
		.logo{
			@include flex();
			align-items: center;
			justify-content: flex-start;
			&.mb{
				margin: 0 0 8px;
			}
			img{
				width: 240px;
				@media screen and (max-width: $phone){
					width: 180px;	
				}
			}
			.circlelink{
				display: inline-block;
				margin: 0 0 0 20px;
				$size: 40px;
				width: $size;
				height: $size;
				@include flex();
				align-items: center;
				justify-content: center;
				background: $glay;
				color: $normal;
				border-radius: 50%;
				@include transition(200ms,$ease:$ease);
				&:hover{
					transform: scale(1.2);
					color: $green;
				}
			}
		}
		.map{
			display: inline-block;
			padding: 8px 40px;
			background: $glay;
			border-radius: 999px;
			margin: 16px 0 0;
			color: $normal;
			&:hover{
				background: $green;
				color: #FFF;
				i{
					color: #fff;
				}
			}
			i{
				color: $green;
				display: inline-block;
				margin: 0 8px 0 0;
			}
		}
	}
	
	.access{
		&__list{
			li{
				margin: 0 0 48px;
				padding: 0 0 48px;
				border-bottom: $border 1px solid;
				position: relative;
				@include flex();
				@media screen and (max-width: $tab){
					margin: 0 0 24px;
					padding: 0 0 24px;
				}
				@media screen and (max-width: $phone){
					padding: 0 0 15px;	
					margin: 0 0 15px;
					display: block;
				}
				&:last-child{
					margin: 0;
					padding: 0;
					border: none;
				}
			}
			&__target{
				position: absolute;
				top: -$pc-head;
				left: 0;
				pointer-events: none;
				@media screen and (max-width: $phone){
					top: -$sp-head + 30px;
				}
			}
			&__map{
				width: 640px;
				height: 350px;
				overflow: hidden;
				@media screen and (max-width: $tab){
					width: 320px;
					height: 200px;
				}
				@media screen and (max-width: $phone){
					width: 100%;
					height: 180px;
				}
				iframe{
					width: 100%;
					height: calc(350px + 400px);
					vertical-align: bottom;
					transform: translateY(-200px);
					@media screen and (max-width: $tab){
						height: calc(200px + 400px);
					}
					@media screen and (max-width: $phone){
						height: calc(180px + 400px);
					}
				}
			}
			&__data{
				width: calc(100% - 640px);
				padding: 0 0 0 48px;
				@media screen and (max-width: $tab){
					width: calc(100% - 320px);
					padding: 0 0 0 32px;
				}
				@media screen and (max-width: $phone){
					width: auto;
					padding: 16px 0 0;
				}
				h4{
					font-weight: 700;
					font-size: 24px;
					line-height: 1.66;
					margin: 0 0 8px;
					@media screen and (max-width: $phone){
						font-size: 20px;
					}
				}
				dl,
				&__addr{
					font-size: 16px;
					@media screen and (max-width: $phone){
						font-size: 15px;	
					}
				}
				dl{
					dt{
						margin: 0 0 12px;
					}
					dd{
						background: $glay;
						padding: 15px;
						font-size: 14px;
					}
				}
			}
		}
	}
	
	.history{
		&__list{
			position: relative;
			&:before{
				content: '';
				width: 3px;
				height: 0;
				background: $green;
				position: absolute;
				border-radius: 999px;
				top: 8px;
				left: 208px;
				transform: translateX(-50%);
				z-index: 3;
				@media screen and (max-width: $phone){
					left: 8px;	
				}
			}
			&._active{
				&:before{
					height: calc(100% - 8px);
					transition: height 4000ms linear;
				}
			}
			&__item{
				@include flex();
				justify-content: flex-start;
				margin: 0 0 32px;
				padding: 0 0 32px;
				border-bottom: $border 1px solid;
				position: relative;
				@media screen and (max-width: $phone){
					display: block;
					margin: 0 0 15px;
					padding: 0 0 15px;
					padding-left: 40px;
				}
				&:before{
					content: '';
					width: 16px;
					height: 16px;
					background: $green;
					border-radius: 50%;
					position: absolute;
					top: 7px;
					left: 200px;
					@media screen and (max-width: $phone){
						left: 0;	
					}
				}
				&:last-child{
					margin-bottom: 0;
					padding-bottom: 0;
					border: none;
				}
				h4{
					width: 300px;
					font-weight: 700;
					font-size: 16px;
					@media screen and (max-width: $phone){
						width: auto;	
					}
				}
				&__box{
					width: calc(100% - 300px);
					@media screen and (max-width: $phone){
						width: auto;
						margin: 12px 0 0;
					}
					.str,
					dl{
						margin: 20px 0 0;
						@media screen and (max-width: $phone){
							margin: 15px 0 0;	
						}
						&.mb{
							margin-bottom: 20px;
							@media screen and (max-width: $phone){
								margin-bottom: 15px;	
							}
						}
						&:first-of-type{
							margin-top: 0;
						}
						dd{
							margin: 20px 0 0;
							@media screen and (max-width: $phone){
								margin: 15px 0 0;	
							}
							&:first-of-type{
								margin: 0;
							}
						}
						ul{
							@include flex();
							justify-content: flex-start;
							li{
								height: 190px;
								margin: 0 20px 0 0;
								@media screen and (max-width: $tab){
									margin: 8px 8px 0 0;
								}
								@media screen and (max-width: $phone){
									height: 120px;
									margin: 7px 15px 0 0;
								}
								&:last-child{
									margin: 0;
									@media screen and (max-width: $tab){
										margin: 8px 8px 0 0;
									}
									@media screen and (max-width: $phone){
										margin: 7px 15px 0 0;	
									}
								}
								img{
									height: 100%;
									@media screen and (max-width: $phone){
										max-width: 100%;	
									}
								}
							}
						}
					}
					.str,
					dt{
						font-weight: 700;
						font-size: 16px;
					}
				}
			}
		}
	}
	
	.vision{
		h3{
			text-align: center;
			font-size: 20px;
			font-weight: 700;
			line-height: 1;
			letter-spacing: .08em;
			@media screen and (max-width: $tab){
				font-size: 16px;
			}
			@media screen and (max-width: $phone){
				font-size: 14px;	
			}
			&:before{
				@include osw;
				content: attr(data-eng);
				font-size: 48px;
				display: block;
				letter-spacing: .12em;
				margin: 0 0 8px;
				@media screen and (max-width: $tab){
					font-size: 32px;
				}
				@media screen and (max-width: $phone){
					font-size: 28px;	
				}
			}
		}
		&__context{
			text-align: center;
			@include notomin();
			font-size: 18px;
			margin: 40px 0 0;
			line-height: 2;
			background: #FFF;
			border: $glay 10px solid;
			padding: 40px;
			@media screen and (max-width: $tab){
				padding: 24px;
				margin: 24px 0 0;
				font-size: 16px;
			}
			@media screen and (max-width: $phone){
				border-width: 4px;
				padding: 0;
				font-size: 13px;
				border: none;
			}
		}
		&__box{
			@include flex();
			margin: 40px 0 0;
			@media screen and (max-width: $tab){
				margin: 24px 0 0;
			}
			@media screen and (max-width: $phone){
				display: block;	
			}
		}
		&__message{
			width: 31.5%;
			background: $glay;
			padding: 24px;
			padding-top: 32px;
			@media screen and (max-width: $tab){
				padding: 16px;
			}
			@media screen and (max-width: $phone){
				width: auto;
				margin: 0 0 15px;
				&:last-child{
					margin: 0;
				}
			}
			dt{
				text-align: center;
				font-weight: 700;
				font-size: 28px;
				line-height: 1;
				letter-spacing: .08em;
				@media screen and (max-width: $tab){
					font-size: 20px;
				}
				@media screen and (max-width: $phone){
					text-align: left;	
				}
				&:before{
					content: attr(data-eng);
					color: $green;
					font-size: 9px;
					display: block;
					margin: 0 0 10px;
				}
			}
			dd{
				margin: 24px 0 0;
				padding: 16px 0 0;
				border-top: #FFF 3px solid;
				text-align: justify;
				@media screen and (max-width: $phone){
					margin: 12px 0 0;
					padding: 8px 0 0;
				}
			}
		}
	}
	
	.centerbox{
		@include flex();
		justify-content: center;
		@media screen and (max-width: $phone){
			display: block;	
		}
		&__item{
			width: 400px;
			text-align: left;
			@media screen and (max-width: $tab){
				width: 320px;	
			}
			@media screen and (max-width: $phone){
				width: auto;	
			}
		}
	}
}