.site-message{
	.message{
		@include flex();
		align-items: flex-end;
		@media screen and (max-width: $tab){
			display: block;	
		}
		&._active{
			> *{
				opacity: 1;
			}
		}
		> *{
			transition: opacity 800ms 400ms linear;
			opacity: 0;
		}
		&__txt{
			width: 680px;
			@media screen and (max-width: $tab){
				margin: 0 auto;
			}
			@media screen and (max-width: $phone){
				width: auto;
			}
		}
		&__photo{
			width: calc(100% - 680px);
			@media screen and (max-width: $tab){
				width: 400px;
				margin: 0 auto;
			}
			@media screen and (max-width: $phone){
				width: 280px;
			}
		}
		img{
			width: 100%;
		}
	}
}