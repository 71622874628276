.site-index{
	.maps{
		position: relative;
		background: #e8f8ef;
		border-bottom: #FFF 8px solid;
		&__flex{
			@include flex();
			align-items: center;
			position: relative;
			@media screen and (max-width: $tab){
				display: block;	
			}
			&__text{
				width: calc(100% - 660px);
				padding: 0 60px 0 0;
				@media screen and (max-width: $tab){
					width: auto;
					text-align: center;
				}
				@media screen and (max-width: $phone){
					padding: 0;	
				}
				h2{
					@include notomin();
					font-size: 36px;
					white-space: nowrap;
					letter-spacing: .08em;
					line-height: 1.72;
					margin: 0 0 16px;
					@media screen and (max-width: $tab){
						font-size: 24px;
						text-align: center;
						margin: 0 0 8px;
						br{
							display: none;
						}
					}
					@media screen and (max-width: $phone){
						white-space: normal;
						font-size: 20px;
						br{
							display: inline;
						}
					}
				}
				.context{
					font-size: 15px;
					text-align: justify;
					@media screen and (max-width: $tab){
						text-align: center;
						width: 80%;
						margin: 0 auto;
					}
					@media screen and (max-width: $phone){
						width: auto;
						text-align: justify;
						margin: 0 15px;
					}
				}
			}
			&__area{
				position: relative;
				width: 660px;
				@media screen and (max-width: $tab){
					margin: 40px auto 0;
				}
				@media screen and (max-width: $phone){
					width: auto;
					margin: 32px 0 0;
				}
				&__stak{
					position: relative;
					width: 460px;
					@media screen and (max-width: $phone){
						display: none;
					}
					img{
						width: 100%;
					}
				}
				&__sp{
					display: none;
					@media screen and (max-width: $phone){
						display: block;
						width: 80%;
						margin: 0 auto;
						img{
							width: 100%;
						}
					}
				}
				&__eng{
					@include osw();
					font-size: 32px;
					line-height: 1;
					position: absolute;
					top: 24px;
					right: 0;
					color: $green;
					@media screen and (max-width: $phone){
						display: none;
					}
				}
				&__list{
					position: absolute;
					top: 86px;
					right: 0;
					width: 280px;
					@media screen and (max-width: $phone){
						width: auto;
						top: 0;
						position: relative;
						padding: 20px 0 0;
					}
					li{
						margin: 0 0 19px;
						@media screen and (max-width: $phone){
							margin: 0 0 10px;
						}
						&:last-child{
							margin: 0;
						}
						a{
							display: block;
							border: $dark 2px solid;
							color: $dark;
							background: #FFF;
							text-align: center;
							padding: 7px 0;
							font-size: 16px;
							@media screen and (max-width: $phone){
								padding: 9px 15px;
								font-size: 15px;
								text-align: left;
								position: relative;
								&:after{
									content: "\f054";
									font-weight: 900;
									font-family: "Font Awesome 5 Free";
									position: absolute;
									top: 50%;
									right: 15px;
									transform: translateY(-50%);
									font-size: 12px;
									transition: right 120ms;
								}
							}
							&:hover{
								background: $dark;
								color: #FFF;
							}
						}
					}
				}
			}
		}
	}
	
	.news{
		background: $glay;
		&__wrap{
			padding: 0 6%;
			min-width: $width;
			max-width: 1600px;
			margin: 0 auto;
			@media screen and (max-width: $tab){
				min-width: 1px;
				max-width: 100%;
				padding: 0 30px;
			}
			@media screen and (max-width: $phone){
				padding: 0 15px;	
			}
			&__tab{
				@include flex();
				align-items: center;
				border-bottom: $green 4px solid;
				margin: 0 0 24px;
				@media screen and (max-width: $phone){
					border-bottom: none;
					margin: 0;
				}
				h2{
					font-size: 18px;
					font-weight: 700;
					line-height: 1;
					padding: 0 60px 8px 0;
					@media screen and (max-width: $tab){
						width: 100%;
						padding-right: 0;
						padding-bottom: 15px;
					}
					@media screen and (max-width: $phone){
						font-size: 15px;	
					}
					&:before{
						content: attr(data-eng);
						font-size: 28px;
						font-weight: 700;
						margin: 0 16px 0 0;
						@media screen and (max-width: $phone){
							font-size: 20px;
						}
					}
				}
				ul{
					@include flex();
					@media screen and (max-width: $tab){
						width: 100%;	
					}
					li{
						margin: 0 4px 0 0;
                        background: #FFF;
                        padding: 10px 0;
                        font-size: 16px;
                        min-width: 180px;
                        display: block;
                        text-align: center;
                        border-radius: 3px 3px 0 0;
						cursor: pointer;
						@media screen and (max-width: $tab){
							min-width: 1px;
							width: calc(25% - 8px);
						}
						@media screen and (max-width: $phone){
							width: calc(50% - 7.5px);
							font-size: 15px;
							border-radius: 3px;
							margin: 0 0 15px;
						}
						&:last-child{
							margin-right: 0;
						}
						&:hover{
							color: $green;
						}
						&.active{
							background: $green;
							color: #FFF !important;
						}
					}
				}
			}
			&__item{
				@include flex();
				justify-content: flex-start;
				margin-left: -24px;
				@media screen and (max-width: $phone){
					display: block;
					margin: 0;
				}
				li{
					width: 33.3%;
					padding-left: 24px;
					&:last-child{
						display: none;
					}
					@media screen and (min-width: 1400px){
						width: 25%;
						&:last-child{
							display: block;
						}
					}
					@media screen and (max-width: $phone){
						width: auto;
						padding: 0;
						margin: 0 0 10px;
						&:last-child{
							margin: 0;
						}
					}
					a{
						background: #FFF;
						color: $normal;
						display: block;
						@media screen and (max-width: $phone){
							@include flex();
							align-items: center;
							padding: 15px;
						}
						.bg{
							width: 100%;
							height: 240px;
							position: relative;
							background-size: cover;
							background-position: center;
							@media screen and (min-width: 1400px){
								height: 200px;
							}
							@media screen and (max-width: $tab){
								height: auto;
								padding-top: 64%;
							}
							@media screen and (max-width: $phone){
								width: 100px;
								height: 60px;
								padding-top: 0;
							}
							&:before,
							&:after{
								transition: opacity 400ms $ease, top 400ms $ease;
								@media screen and (max-width: $phone){
									display: none;
								}
							}
							&:before{
								content: '';
								background: #000;
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								opacity: 0;
							}
							&:after{
								content: '続きを読む';
								background: #FFF;
								position: absolute;
								top: 60%;
								left: 50%;
								transform: translateX(-50%) translateY(-50%);
								padding: 4px 32px;
								border-radius: 999px;
								opacity: 0;
							}
						}
						&:hover{
							.bg{
								&:before{
									opacity: .32;
								}
								&:after{
									opacity: 1;
									top: 50%;
								}
							}
						}
					}
					dl{
						padding: 16px 20px;
						@media screen and (max-width: $phone){
							width: calc(100% - 100px);
							padding: 0 0 0 15px;
						}
						dd{
							font-size: 12px;
							display: inline-block;
							width: 100px;
							text-align: center;
							border-radius: 3px;
							background: darken($glay,2%);
						}
						dt{
							font-weight: 700;
							margin: 4px 0 0;
						}
					}
				}
			}
		}
	}
	.works{
		position: relative;
		&__data{
			text-align: center;
			@include flex();
			justify-content: center;
			font-size: 24px;
			font-weight: 700;
			margin: 0 0 32px;
			@media screen and (max-width: $phone){
				font-size: 16px;
				margin: 0 15px 24px;
			}
			dt{
				width: 100%;
				margin: 0 0 4px;
			}
			dd{
				font-size: 40px;
				padding: 0 72px 0 0;
				position: relative;
				@media screen and (max-width: $tab){
					font-size: 20px;	
				}
				@media screen and (max-width: $phone){
					font-size: 14px;
					padding: 0 32px 0 0;
				}
				&:after{
					content: '';
					width: 7px;
					height: 72%;
					position: absolute;
					top: 0;
					right: 26px;
					transform: skewX(-20deg) translateY(8px);
					background: $normal;
					opacity: .12;
					@media screen and (max-width: $phone){
						right: 11px;
						width: 3px;
						top: -14%;
					}
				}
				&:last-of-type{
					padding: 0;
					&:after{
						display: none;
					}
				}
				strong{
					@include osw();
					font-size: 64px;
					color: $green;
					line-height: 1;
					display: inline-block;
					margin: 0 4px;
					transform: translateY(1px);
					@media screen and (max-width: $tab){
						font-size: 40px;	
					}
					@media screen and (max-width: $phone){
						font-size: 26px;	
					}
					&.animation{
						animation: scalescore 600ms $ease forwards;
						@keyframes scalescore{
							0%{
								transform: scale(1) translateY(1px);
							}
							50%{
								transform: scale(1.1) translateY(1px);
							}
							100%{
								transform: scale(1) translateY(1px);
							}
						}
					}
					&.em3{
						min-width: 3.2em;
					}
					&.em4{
						min-width: 4.2em;
					}
				}
			}
		}
		&__section{
			position: relative;
			@include flex();
			align-items: center;
			justify-content: flex-end;
			max-width: 1920px;
			@media screen and (max-width: $tab){
				display: block;	
				padding: 0 30px;
			}
			@media screen and (max-width: $phone){
				padding: 0 15px;	
			}
			.swiper-wrapper{
				transition-timing-function: $ease;
			}
			&__bg{
				width: 56%;
				position: relative;
				@media screen and (max-width: $tab){
					width: 100%;
					-webkit-perspective: 500px;
					perspective: 500px;
				}
				.workslide{
					opacity: 0;
					transition: opacity 400ms;
					&.swiper-slide-active{
						opacity: 1;
						transition: opacity 600ms 300ms;
					}
					&__bg{
						width: 100%;
						height: 460px;
						background-size: cover;
						background-position: center;
						@media screen and (max-width: $tab){
							height: auto;
							padding-top: 64%;
						}
					}
					&__txt{
						margin: 20px 0 0 40px;
						@include flex();
						align-items: center;
						justify-content: flex-start;
						@media screen and (max-width: $tab){
							margin-left: 0;
						}
						@media screen and (max-width: $phone){
							margin-top: 15px;	
						}
						dt{
							background: $green;
							color: #FFF;
							font-size: 13px;
							padding: 4px 16px;
							text-align: center;
							margin: 0 16px 0 0;
						}
						dd{
							
						}
					}
				}
			}
			.works-pagination{
				position: absolute;
				right: 56px;
				bottom: 15px;
				@include flex();
				justify-content: flex-start;
				z-index: 1;
				@media screen and (max-width: $phone){
					position: relative;
					right: auto;
					bottom: auto;
					top: 0;
					left: 0;
					margin: 15px 0 0;
				}
				.swiper-pagination-bullet{
					width: 10px;
					height: 10px;
					border-radius: 50%;
					opacity: 1;
					background: darken($border, 4%);
					margin: 0 10px 0 0;
					&:last-of-type{
						margin: 0;
					}
					&.swiper-pagination-bullet-active{
						background: $green;
					}
				}
			}
			.works-next,
			.works-prev{
				width: 10px;
				height: 17px;
				background-image: url("../../../images/common/controll.png");
				background-position: 0 0;
				background-size: 10px;
				cursor: pointer;
				position: absolute;
				z-index: 1;
				bottom: 12px;
				right: 24px;
				@media screen and (max-width: $phone){
					bottom: -4px;
				}
				&:hover{
					background-image: url("../../../images/common/controll_on.png");
				}
			}
			.works-next{
				transform: rotateY(180deg);
				right: 0;
			}
			&__content{
				padding: 80px 48px;
				width: 44%;
				@include flex();
				justify-content: center;
				@media screen and (max-width: $tab){
					width: auto;
					padding: 48px 0 0;
					margin: 48px 0 0;
					border-top: $border 1px solid;
				}
				@media screen and (max-width: $phone){
					padding: 16px 0 0;
					margin: 16px 0 0;
				}
				&__item{
					padding: 0 48px 0 0;
					max-width: 600px;
					@media screen and (max-width: $tab){
						max-width: 100%;
						width: 80%;
						margin: 0 auto;
						text-align: center;
						padding: 0;
					}
					@media screen and (max-width: $phone){
						width: auto;
						margin: 0;
					}
                    h2{
                        font-size: 36px;
                        font-weight: 700;
						line-height: 1;
						letter-spacing: .024em;
						@media screen and (max-width: $tab){
							font-size: 32px;	
						}
						@media screen and (max-width: $phone){
							font-size: 28px;
							padding: 12px 0 0;
						}
						&:after{
							content: attr(data-eng);
							display: inline-block;
							margin: 0 0 0 20px;
							font-size: 13px;
							color: $green;
							@media screen and (max-width: $tab){
								display: block;	
								margin: 12px 0 0;
							}
						}
                    }
                    .desc{
                        text-align: justify;
						margin: 24px 0 0;
                    }
					.example{
						background: $glay;
						padding: 15px 20px;
						position: relative;
						border-radius: 3px;
						margin: 20px 0 0;
					}
				}
			}
		}
	}
	
	.feature{
		position: relative;
		@media screen and (max-width: $tab){
			padding-top: 0 !important;	
			padding-bottom: 0 !important;	
		}
		&:before{
			content: '';
			width: 80%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: $glay;
			z-index: -1;
			@media screen and (max-width: $tab){
				display: none;
			}
		}
		&__section{
			position: relative;
			@include flex();
			justify-content: flex-start;
			align-items: center;
			max-width: 1920px;
			@media screen and (max-width: $tab){
				max-width: 100%;
				display: block;
				padding: 0 30px;
			}
			@media screen and (max-width: $phone){
				padding: 0 15px;	
			}
			&__bg{
				width: 56%;
				position: relative;
				order: 2;
				@media screen and (max-width: $tab){
					width: 100%;	
				}
				.workslide{
					opacity: 0;
					transition: opacity 400ms;
					&.swiper-slide-active{
						opacity: 1;
						transition: opacity 600ms 300ms;
					}
					&__bg{
                        width: 100%;
                        min-height: 500px;
						height: 34vw;
                        background-size: cover;
                        background-position: center;
						@media screen and (max-width: $phone){
							min-height: 1px;
							height: 200px;	
						}
					}
				}
			}
			&__content{
				padding: 16px 48px;
				width: 44%;
				@include flex();
				justify-content: center;
				@media screen and (max-width: $tab){
					width: auto;
					padding: 48px 0 0;
					margin: 48px 0 0;
					border-top: $border 1px solid;
				}
				@media screen and (max-width: $phone){
					padding: 32px 0 0;
					margin: 0;
					border: none;
				}
				&__item{
					padding: 0 0 0 48px;
					max-width: 600px;
					@media screen and (max-width: $tab){
						max-width: 100%;
						width: 80%;
						margin: 0 auto;
						text-align: center;
						padding: 0;
					}
					@media screen and (max-width: $phone){
						width: auto;	
					}
                    h2{
                        font-size: 36px;
                        font-weight: 700;
						line-height: 1;
						letter-spacing: .024em;
						@media screen and (max-width: $tab){
							font-size: 32px;	
						}
						@media screen and (max-width: $phone){
							font-size: 28px;
						}
						&:after{
							content: attr(data-eng);
							display: inline-block;
							margin: 0 0 0 20px;
							font-size: 13px;
							color: $green;
						}
						&.afterend{
							&:after{
								margin: 12px 0 0;
								display: block;
							}
						}
                    }
                    .desc{
                        text-align: justify;
						margin: 24px 0 0;
                    }
                    .merit{
						@include flex();
						margin: 24px 0 0;
						figure{
							background: #FFF;
							padding: 15px;
							@media screen and (max-width: $tab){
								padding: 0;	
							}
							img{
								width: 100%;
							}
						}
						dl{
							width: 50%;
							background: #FFF;
							padding: 15px 20px;
							position: relative;
							&:first-of-type{
								&:before{
									content: '';
									width: 2px;
									height: 100%;
									background: $glay;
									position: absolute;
									top: 0;
									right: -1px;
								}
							}
							dt{
								color: $green;
								font-weight: 700;
							}
							dd{
								font-size: 13px;
								line-height: 1.66;
								text-align: justify;
							}
						}
                    }
					.example{
						background: $glay;
						padding: 15px 20px;
						position: relative;
						border-radius: 3px;
						margin: 20px 0 0;
					}
				}
			}
		}
	}
	.business{
		position: relative;
		&.wrap{
			@media screen and (max-width: $phone){
				padding-bottom: 0;
				border-bottom: $dark 16px solid;
			}
		}
		h2{
			background: $green url(/"../../../images/index/black_dotto.png");
			background-size: 16px;
			text-align: center;
			padding: 56px 0;
			margin: 0 0 80px;
			color: #FFF;
			font-size: 38px;
			letter-spacing: .08em;
			line-height: 1;
			font-weight: 700;
			position: relative;
			@media screen and (max-width: $tab){
				padding: 32px;
				font-size: 32px;
			}
			@media screen and (max-width: $phone){
				font-size: 24px;
				padding: 16px;
				padding-bottom: 18px;
				margin: 0;
				z-index: 2;
			}
			&:after{
				content: attr(data-eng);
				font-size: 16px;
				display: block;
				margin: 14px 0 0;
				@media screen and (max-width: $tab){
					font-size: 15px;
				}
				@media screen and (max-width: $phone){
					font-size: 13px;
					margin: 8px 0 0;
				}
			}
			&:before{
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 24px 22px 0 22px;
				border-color: darken($green,3%) transparent transparent transparent;
				position: absolute;
				left: 50%;
				bottom: -24px;
				transform: translateX(-50%);
				@media screen and (max-width: $phone){
					border-width: 12px 11px 0 11px;
					bottom: -12px;
				}
			}
		}
		&__list{
			@include flex();
			align-items: center;
			justify-content: center;
			li{
				width: 33%;
				&:nth-child(2){
					width: 34%;
					@media screen and (max-width: $tab){
						width: 50%;	
					}
				}
				@media screen and (max-width: $tab){
					width: 50%;
					&:last-child{
						width: 100%;
					}
				}
				a{
					display: block;
					height: 22.5vw;
					background-size: cover;
					background-position: center;
					position: relative;
					@media screen and (max-width: $phone){
						height: 120px;	
					}
					&:before{
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: #000;
						opacity: .48;
						transition: opacity 400ms;
					}
					&:hover{
						&:before{
							opacity: .16;
						}
					}
					h3{
						position: absolute;
						z-index: 2;
						top: 50%;
						left: 50%;
						transform: translateX(-50%) translateY(-50%);
						color: #FFF;
						font-size: 32px;
						font-weight: 700;
						width: 100%;
						padding: 0 15px;
						text-align: center;
						text-shadow: 0 0 24px rgba(#000,.12), 0 0 24px rgba(#000,.12);
						@media screen and (max-width: $phone){
							font-size: 22px;	
						}
						&:after{
							content: attr(data-eng);
							font-size: 17px;
							font-weight: 700;
							color: lighten($green, 8%);
							display: block;
							text-align: center;
							line-height: 1;
							text-shadow: 0 0 16px rgba(#000,.12), 0 0 8px rgba(#000,.32);
							@media screen and (max-width: $phone){
								font-size: 14px;	
							}
						}
					}
				}
			}
		}
	}
	.other{
		&__list{
			@include flex();
			margin: 0 0 32px;
			&__item{
				width: 50%;
				position: relative;
				@include flex();
				align-items: center;
				justify-content: flex-start;
				padding: 0 0 0 16px;
				&--right{
					justify-content: flex-end;
					padding: 0 16px 0 0;
				}
				&__bg{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 28vw;
					background-position: center;
					background-size: cover;
				}
				&__content{
					display: block;
					position: relative;
					margin: 28vw 0 0;
					width: calc(#{$width} / 2 - 16px - 40px);
					padding: 24px 40px;
					color: $normal;
					font-size: 20px;
					background: #FFF;
					transform: translateY(-50%);
					z-index: 2;
					box-shadow: 0 0 32px rgba(#000,.12);
					i{
						display: inline-block;
						margin: 0 10px 0 0;
						color: $green;
					}
					&:before{
						content: "\f054";
						font-weight: 900;
						font-family: "Font Awesome 5 Free";
						position: absolute;
						top: 50%;
						right: 32px;
						opacity: .16;
						transform: translateY(-50%);
						font-size: 16px;
						transition: right 120ms, opacity 120ms, color 120ms;
					}
					&:hover{
						color: $green;
						&:before{
							right: 24px;
							opacity: 1;
							color: $green;
						}
					}
				}
			}
		}
	}
	.blog{
		&__list{
			@include flex();
			&__item{
				width: calc(50% - 12px);
				margin: 12px 0 0;
				&:nth-child(-n+2){
					margin-top: 0;
				}
				a{
					display: block;
					border: $glay 4px solid;
					padding: 15px;
					color: $normal;
					@include flex();
					align-items: center;
					transition: border 300ms, boxshadow 300ms;
					position: relative;
					&:hover{
						border-color: #FFF;
						box-shadow: 0 0 32px rgba(#000,.12);
						z-index: 2;
					}
				}
				&__bg{
					width: 100px;
					height: 100px;
					background-color: $glay;
					background-size: cover;
					background-position: center;
				}
				&__content{
					width: calc(100% - 100px);
					padding: 0 0 0 32px;
					time,
					span.category{
						width: 110px;
						font-size: 12px;
						background: $green;
						color: #FFF;
						padding: 3px 0;
						display: inline-block;
						margin: 0 8px 0 0;
						text-align: center;
					}
					span.category{
						background: $glay;
						color: $normal;
					}
					h4{
						font-size: 17px;
						font-weight: 700;
						margin: 4px 0 0;
					}
				}
			}
		}
		&__more{
			position: absolute;
			top: -4px;
			right: 40px;
			background: $glay;
			border-radius: 999px;
			padding: 8px 24px;
			font-size: 14px;
			color: $normal;
			i{
				color: $green;
				display: inline-block;
				margin: 0 8px 0 0;
			}
			&:hover{
				background: $green;
				color: #FFF;
				i{
					color: #FFF;
				}
			}
		}
	}
}