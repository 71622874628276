.site-feature{
	.titlearea{
		margin: 0 0 80px;
		text-align: center;
		padding: 40px 32px;
		border: $glay 10px solid;
		@media screen and (max-width: $tab){
			margin: 0 0 48px;
			padding: 24px;
		}
		@media screen and (max-width: $phone){
			margin: 0 0 32px;
			padding: 15px;
			border-width: 4px;
		}
		dt{
			@include min();
			font-size: 32px;
			font-weight: 700;
			margin: 0 0 8pxx;
			@media screen and (max-width: $tab){
				font-size: 28px;	
			}
			@media screen and (max-width: $phone){
				font-size: 20px;	
			}
		}
		dd{
			font-size: 17px;
			@media screen and (max-width: $tab){
				font-size: 16px;	
			}
			@media screen and (max-width: $phone){
				font-size: 14px;	
			}
		}
	}
	.str{
		color: $green;
		text-align: center;
		font-size: 28px;
		line-height: 1;
		font-weight: 700;
		@media screen and (max-width: $phone){
			line-height: 1.5;
			font-size: 18px;
		}
	}
	.txtarea{
		font-size: 15px;
		margin: 20px 12% 56px;
		text-align: justify;
		@media screen and (max-width: $phone){
			font-size: 13.5px;
			margin: 20px 15px 32px;
		}
	}
	.onebox{
		&__item{
			margin: 0 0 32px;
			position: relative;
			background: $glay;
			padding: 32px;
			@media screen and (max-width: $tab){
				padding: 30px;	
			}
			@media screen and (max-width: $phone){
				padding: 15px;
				margin: 0 0 16px;
			}
			&__clum{
				@include flex();
				align-items: center;
				@media screen and (max-width: $phone){
					display: block;	
				}
				&__pic{
					width: 400px;
					@media screen and (max-width: $tab){
						width: 320px;	
					}
					@media screen and (max-width: $phone){
						width: auto;	
					}
					&.s{
						width: 280px;
						margin: 0 0 0 0;
						@media screen and (max-width: $phone){
							width: auto;	
						}
					}
					img{
						width: 100%;
					}
				}
				&__txt{
					width: calc(100% - 400px);
					padding: 0 0 0 48px;
					position: relative;
					@media screen and (max-width: $tab){
						width: calc(100% - 320px);
						padding: 0 0 0 32px;
					}
					@media screen and (max-width: $phone){
						width: auto;
						padding: 16px 0 0;
					}
					&.s{
						width: calc(100% - 600px);
						padding: 0 0 0 16px;
						@media screen and (max-width: $phone){
							width: auto;
							padding: 16px 0 0;
						}
					}
					dt{
						font-size: 24px;
						font-weight: 700;
						margin: 0 0 8px;
						padding: 0 0 0 50px;
						position: relative;
						@media screen and (max-width: $tab){
							line-height: 1.5;
							font-size: 20px;
						}
						@media screen and (max-width: $phone){
							font-size: 17px;
							padding: 0 0 0 32px;
						}
						&:before{
							content: attr(data-num);
							background: $green;
							line-height: 1;
							font-size: 18px;
							color: #FFF;
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%);
							width: 36px;
							height: 36px;
							@include flex();
							align-items: center;
							justify-content: center;
							@media screen and (max-width: $phone){
								font-size: 15px;
								width: 24px;
								height: 24px;
							}
						}
					}
					dd{
						font-size: 15px;
						text-align: justify;
						@media screen and (max-width: $tab){
							font-size: 14px;	
						}
					}
				}
			}
		}
		h3.borders{
			margin-top: 40px;
			@media screen and (max-width: $phone){
				margin-top: 24px;	
			}
		}
	}
	.videoclum{
		margin: 64px 0 0;
		@media screen and (max-width: $tab){
			margin: 48px 0 0;	
		}
		@media screen and (max-width: $phone){
			margin: 32px 0 0;	
		}
		&__list{
			margin: 0 0 0 -32px;
			@include flex();
			@media screen and (max-width: $phone){
				display: block;
				margin: 0;
			}
			li{
				width: 33.3%;
				padding: 0 0 0 32px;
				@media screen and (max-width: $tab){
					width: 50%;
					margin: 32px 0 0;
					&:nth-child(-n+2){
						margin-top: 0;
					}
				}
				@media screen and (max-width: $phone){
					width: auto;
					margin: 0 0 16px;
					padding: 0;
					&:last-child{
						margin: 0;
					}
				}
				video{
					width: 100%;
					vertical-align: bottom;
				}
			}
		}
		&__cap{
			text-align: center;
			background: $border;
			padding: 8px 15px;
			margin: 0 0 24px;
			position: relative;
			border-radius: 3px;
			&:after{
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 16px 12px 0 12px;
				border-color: $border transparent transparent transparent;
				position: absolute;
				bottom: -16px;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
	.ars{
		@include flex();
		margin: 0 0 24px;
		&:last-of-type{
			margin: 0;
		}
		dt{
			width: 14%;
			@include flex();
			align-items: center;
			font-size: 20px;
			font-weight: 700;
			@media screen and (max-width: $tab){
				font-size: 15px;	
			}
			@media screen and (max-width: $phone){
				width: 100%;
				font-size: 15px;
				margin: 0 0 8px;
			}
		}
		dd{
			//MAX 86% | 4;
			width: 15%;
			padding: 15px;
			font-size: 14px;
			font-weight: 700;
			text-align: center;
			color: #FFF;
			box-shadow: inset 0 3px 3px rgba(#000,.04);
			@media screen and (max-width: $tab){
				font-size: 12px;	
			}
			@media screen and (max-width: $phone){
				font-size: 10px;
				line-height: 1.5;
				width: 17%;
				padding: 8px;
			}
			&.large{
				width: 41%;
				@media screen and (max-width: $phone){
					width: 49%;
				}
			}
			&.middle{
				width: 23%;
				@media screen and (max-width: $phone){
					width: 25%;
				}
			}
			&.small{
				width: 10%;
				@media screen and (max-width: $phone){
					width: 16%;	
				}
			}
			&:first-of-type{
				border-radius: 4px 0 0 4px;
			}
			&:last-of-type{
				border-radius: 0 4px 4px 0;
			}
		}
		&.glay{
			dd{
				background: #444;
				&:nth-of-type(odd){
					background: lighten(#444,12%);
				}
			}
		}
		&.green{
			dd{
				background: $green;
				&:nth-of-type(odd){
					background: lighten($green,4%);
				}
				&:last-of-type{
					background: #FFF;
					color: $normal;
					box-shadow: none;
					strong{
						color: $green;
						font-weight: 700;
						font-size: 20px;
						line-height: 1;
						@media screen and (max-width: $phone){
							display: block;	
						}
					}
				}
			}
		}
	}
	.alt{
		text-align: right;
		font-size: 11px;
		margin: 12px 0 0;
	}
}