.site-recruit{
	.fv{
		height: 50vw;
		margin-top: $pc-head;
		position: relative;
		@include flex();
		align-items: center;
		justify-content: center;
		overflow: hidden;
		@media screen and (max-width: $tab){
			height: 64vw;
		}
		@media screen and (max-width: $phone){
			margin-top: $sp-head;
			height: 320px;
		}
		&:before{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: url("../../../images/recruit/main.jpg") no-repeat center;
			background-size: cover;
			transform: scale(1.2);
			transition: transform 10000ms linear;
		}
		&.active{
			&:before{
				transform: scale(1);
			}
			.fv__title{
				opacity: 1;
				margin-top: 0;
			}
		}
		&__conner{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 3;
			pointer-events: none;
			@media screen and (max-width: $tab){
				transform: translate(0,0) !important;
			}
			&:before,
			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 16vw;
				height: 16vw;
				background: $green;
				opacity: .88;
				transform: translateX(-50%) translateY(-50%) rotate(45deg);
			}
			&:after{
				top: auto;
				left: auto;
				right: 0;
				bottom: 0;
				transform: translateX(50%) translateY(50%) rotate(45deg);
			}
		}
		&__title{
			position: relative;
			z-index: 2;
			opacity: 0;
			margin-top: 40px;
			transition: opacity 1200ms $ease, margin 1200ms $ease;
			@media screen and (max-width: $tab){
				transform: translate(0,0) !important;
			}
			figure{
				height: 256px;
				margin: 0 auto;
				border-radius: 16px;
				overflow: hidden;
				box-shadow: 0 12px 48px rgba(#000,.24);
				@media screen and (max-width: $tab){
					height: 200px;	
				}
				@media screen and (max-width: $phone){
					height: auto;
					width: 280px;
				}
				img{
					height: 100%;
					@media screen and (max-width: $phone){
						height: auto;
						width: 100%;
					}
				}
			}
			&__btn{
				margin: 32px 0 0;
				text-align: center;
				@media screen and (max-width: $phone){
					margin: 16px 0 0;	
				}
				a{
					background: #FFF;
					border-radius: 999px;
					color: $normal;
					font-size: 18px;
					display: inline-block;
					padding: 10px 80px;
					box-shadow: 0 0 48px rgba(#000,.24);
					@include transition(200ms);
					@media screen and (max-width: $phone){
						padding: 10px 0;
						width: 280px;
					}
					&:hover{
						background: $green;
						color: #FFF;
						box-shadow: 0 0 48px rgba($green,.64);
						i{
							color: #FFF;
						}
					}
					i{
						display: inline-block;
						margin: 0 10px 0 0;
						color: $green;
						@include transition(200ms);
					}
				}
			}
		}
	}
	.dataview{
		padding-top: 120px;
		padding-bottom: 120px;
		@media screen and (max-width: $phone){
			padding: 32px 15px;	
		}
	}
	#viewdata{
		position: relative;
		canvas{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
		}
	}
	.glist{
		@include flex();
		position: relative;
		@media screen and (max-width: $phone){
			display: block;	
		}
		&:before,
		&:after{
			content: '';
			width: 32px;
			height: 32px;
			background: darken($glay, 2%);
			border-radius: 50%;
			position: absolute;
			top: calc(50% - 8px);
			left: 30.8%;
			transform: translateY(-50%);
			@media screen and (max-width: $phone){
				display: block;	
			}
		}
		&:after{
			left: auto;
			right: 30.8%;
		}
		li{
			width: 29%;
			@media screen and (max-width: $phone){
				width: 100%;
				margin: 0 auto 24px;
				padding: 0 0 24px;
				border-bottom: $border 1px solid;
				text-align: center;
				&:last-child{
					margin-bottom: 0;
					padding-bottom: 0;
					border-bottom: none;
				}
			}
			img{
				width: 100%;
				@media screen and (max-width: $phone){
					width: 64%;
				}
			}
			figcaption{
				text-align: center;
				font-size: 16px;
				font-weight: 700;
			}
		}
	}
	.joblist{
		@include flex();
		justify-content: flex-start;
		margin: 0 0 40px;
		@media screen and (max-width: $phone){
			margin: 0 0 24px;
			display: block;
		}
		li{
			width: calc(20% - 4px);
			margin: 4px 4px 0 0;
			background: #FFF;
			font-size: 16px;
			text-align: center;
			cursor: pointer;
			position: relative;
			@media screen and (max-width: $phone){
				width: auto;
				margin: 0 0 8px;
				&:last-child{
					margin: 0;
				}
			}
			&:nth-child(5n+5){
				margin-right: 0;
			}
			&:nth-child(-n+5){
				margin-top: 0;
			}
			&:hover{
				a{
					color: $green;
				}
			}
			a{
				color: $normal;
				display: block;
				padding: 15px;
			}
			&:before{
				transform: scale(1.2);
				opacity: 0;
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border: $green 2px solid;
                position: absolute;
				@include transition(120ms, $ease: $ease);
				pointer-events: none;
				@media screen and (max-width: $phone){
					transform: scale(1);
				}
			}
			&.active{
				pointer-events: none;
				cursor: auto;
				z-index: 2;
				box-shadow: 0 0 32px rgba($green,.16);
				a{
					color: $green;
				}
				&:before{
					transform: scale(1);
					opacity: 1;
				}
			}
		}
	}
	.jobbox{
		background: #FFF;
		padding: 40px;
		@media screen and (max-width: $phone){
			padding: 15px;	
		}
		&__catch{
			@include flex();
			align-items: center;
			@media screen and (max-width: $phone){
				width: auto;
				display: block;
			}
			figure{
				width: 50%;
				@media screen and (max-width: $phone){
					width: auto;
				}
				img{
					width: 100%;
				}
			}
			dl{
				width: 50%;
				padding: 0 0 0 40px;
				@media screen and (max-width: $phone){
					width: auto;
					padding: 20px 0 0;
				}
				dd{
					font-size: 26px;
					font-weight: 700;
					line-height: 1.5;
					margin: 0 0 20px;
					padding: 0 0 16px;
					border-bottom: $normal 1px solid;
					@media screen and (max-width: $phone){
						font-size: 16px;
						margin: 0 0 15px;
						padding: 0 0 15px;
					}
				}
				dt{
					font-size: 16px;
					background: $green;
					color: #FFF;
					padding: 4px 32px;
					display: inline-block;
					@media screen and (max-width: $phone){
						font-size: 15px;
						padding: 2px 15px;
					}
				}
			}
		}
		.tablestyle{
			border: $border 1px solid;
			margin: 32px 0 0;
			tbody{
				tr{
					border-color: $border;
					border-width: 1px;
					th,
					td{
						border-color: $border;
						border-width: 1px;
					}
					th{
						background: $glay;
					}
				}
			}
		}
	}
    .stafflist{
        @include flex();
		justify-content: flex-start;
		margin: 0 0 64px -56px;
		@media screen and (max-width: $phone){
			margin: 0 0 24px -24px;
			padding: 0 24px;
		}
        li{
            width: 25%;
			margin: 56px 0 0;
			padding: 0 0 0 56px;
			@media screen and (max-width: $phone){
				width: 50%;
				padding: 0 0 0 24px;
				margin: 0 0 24px;
			}
			&:nth-child(-n+4){
				margin-top: 0;
			}
			a{
				display: block;
				color: $normal;
				position: relative;
			}
            figure{
                overflow: hidden;
                border-radius: 50%;
                img{
                    width: 100%;
                }
            }
			dl{
				margin: 16px 0 0;
				text-align: center;
				dt{
					background: $glay;
					border-radius: 999px;
					padding: 4px 0;
					margin: 12px 0 0;
				}
				dd{
					font-size: 16px;
					font-weight: 700;
					&:before{
						content: attr(data-eng);
						@include osw();
						display: block;
						font-size: 10px;
						text-align: center;
						color: $green;
					}
				}
			}
        }
    }
	
	
	//interview
	#interview{
		border-bottom: $glay 12px solid;
	}
	.intvfv{
		margin-top: $pc-head;
		height: 460px;
		background: $glay;
		position: relative;
		overflow: hidden;
		@include flex();
		align-items: center;
		@media screen and (max-width: $tab){
			height: 400px;	
		}
		@media screen and (max-width: $phone){
			display: block;
			height: auto;
			background: $dark;
			margin-top: $sp-head;
		}
		&__bg{
			position: absolute;
			top: 0;
			right: 0;
			width: 60%;
			height: 100%;
			background-size: cover;
			background-position: center;
			@media screen and (max-width: $phone){
				position: relative;
				right: auto;
				left: 0;
				width: 100%;
				height: 240px;
			}
		}
		&:before{
			content: '';
			width: 60%;
			height: 100%;
			position: absolute;
			top: 0;
			left: -10%;
			background: $dark;
			z-index: 2;
			transform: skewX(-20deg);
			@media screen and (max-width: $phone){
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 12px 16px 12px;
				border-color: transparent transparent $dark transparent;
				transform: translateX(-50%);
				background: none;
				top: 224px;
				left: 50%;
			}
		}
        &:after{
            content: '';
            position: absolute;
            top: 0;
            left: -4vw;
            width: 16vw;
            height: 16vw;
            background: $green;
			z-index: 3;
            transform: translateX(-50%) translateY(-50%) rotate(32deg);
			@media screen and (max-width: $phone){
				display: none;
			}
        }
		.display{
			z-index: 2;
			color: #FFF;
			@media screen and (max-width: $tab){
				width: 100%;	
			}
			@media screen and (max-width: $phone){
				padding: 24px 15px;	
			}
			> *{
				max-width: 45%;
				@media screen and (max-width: $phone){
					max-width: 100%;	
				}
			}
			h1{
				font-size: 16px;
				font-weight: 500;
				line-height: 1;
				margin: 0 0 20px;
				padding: 0 0 24px;
				border-bottom: rgba(#FFF,.12) 1px solid;
				position: relative;
				@media screen and (max-width: $tab){
					font-size: 13px;	
				}
				&:before{
					content: 'Interview';
					color: $green;
					@include osw();
					font-size: 24px;
					display: inline-block;
					margin: 0 20px 0 0;
				}
				&:after{
					content: '';
					width: 24%;
					height: 1px;
					background: $green;
					position: absolute;
					left: 0;
					bottom: -1px;
				}
			}
			.catch{
				font-size: 32px;
				letter-spacing: .12em;
				font-weight: 500;
				line-height: 1.44;
				margin: 0 0 48px;
				opacity: .96;
				@media screen and (max-width: $tab){
					font-size: 24px;	
				}
				@media screen and (max-width: $phone){
					font-size: 20px;
					margin: 0 0 16px;	
				}
			}
			.job{
				@include flex();
				margin: 0 0 6px;
				justify-content: flex-start;
				opacity: .72;
				dt{
					&:after{
						content: '/';
						display: inline-block;
						margin: 0 4px;
					}
				}
			}
			.name{
				@include flex();
				justify-content: flex-start;
				align-items: flex-end;
				line-height: 1;
				dt{
					font-size: 28px;
					font-weight: 500;
					opacity: .72;
					@media screen and (max-width: $phone){
						font-size: 24px;
						opacity: 1;	
					}
				}
				dd{
					font-size: 13px;
					opacity: .32;
					display: inline-block;
					margin: 0 0 0 20px;
				}
			}
		}
	}
	.intvbg{
		background: url("../../../images/recruit/black_dotto.png") repeat;
		background-size: 16px;
	}
	.intvlist{
		li{
			border: $glay 8px solid;
			background: #FFF;
			border-radius: 16px;
			padding: 32px;
			position: relative;
			margin: 0 0 48px;
			@include flex();
			align-items: flex-start;
			@media screen and (max-width: $phone){
				border-width: 4px;
				padding: 15px;
				margin: 0 0 24px;
			}
			@for $i from 1 through 100{
				&:nth-child(#{$i}){
					&:after{
						content: '質問.#{$i}';
					}
				}
			}
			&:after{
				position: absolute;
				top: -24px;
				right: 40px;
				background: $green;
				font-size: 20px;
				font-weight: 700;
				color: #FFF;
				border-radius: 999px;
				padding: 4px 32px;
				box-shadow: 0 4px 16px rgba(darken($green,8%),.32);
				@media screen and (max-width: $phone){
					right: 15px;
					font-size: 12px;
					top: -15px;
				}
			}
			&:before{
				content: '';
				width: 0;
                height: 0;
                border-style: solid;
                border-width: 24px 8px 0 8px;
                border-color: $green transparent transparent transparent;
				transform: skewX(-20deg);
				position: absolute;
				right: 80px;
				top: 16px;
				@media screen and (max-width: $phone){
					display: none;
				}
			}
			figure{
				width: 440px;
				border-radius: 6px;
				overflow: hidden;
				@media screen and (max-width: $tab){
					width: 100%;	
				}
				img{
					width: 100%;
				}
				& + dl{
					width: calc(100% - 440px);
					padding: 0 0 0 40px;
					@media screen and (max-width: $tab){
						width: 100%;
						padding: 32px 0 0;
					}
					@media screen and (max-width: $phone){
						padding: 24px 0 0;	
					}
				}
			}
			dl{
				dt,dd{
					padding-left: 36px;
					position: relative;
					&:before{
						content: 'Q';
						position: absolute;
						top: 0;
						left: 0;
						color: $green;
						font-size: 24px;
					}
				}
				dt{
					font-size: 24px;
					font-weight: 700;
					padding-bottom: 12px;
					margin-bottom: 12px;
					border-bottom: $glay 5px solid;
					@media screen and (max-width: $tab){
						font-size: 20px;	
					}
					@media screen and (max-width: $phone){
						font-size: 18px;	
					}
				}
				dd{
					font-size: 17px;
					@media screen and (max-width: $tab){
						font-size: 15px;	
					}
					@media screen and (max-width: $phone){
						font-size: 14px;	
					}
					&:before{
						content: 'A';
						font-weight: 700;
						left: 2px;
						top: -2px;
						color: #f65064;
					}
				}
			}
		}
	}
}