.site-works{
	.swiperbox{
		padding: 0 48px;
		position: relative;
		@media screen and (max-width: $tab){
			padding: 0;	
		}
		.works-button-next,
		.works-button-prev{
			position: absolute;
			top: 50%;
			left: 0;
			font-size: 32px;
			color: darken($border, 8%);
			transform: translateY(-50%);
			cursor: pointer;
			@media screen and (max-width: $tab){
				display: none;
			}
			&:hover{
				color: $green;
			}
		}
		.works-button-next{
			left: auto;
			right: 0;
		}
		.works-pagination{
			margin: 24px 0 0;
			@include flex();
			align-items: center;
			justify-content: center;
			.swiper-pagination-bullet{
				width: 9px;
				height: 9px;
				margin: 0 5px;
				background: darken($border, 8%);
				opacity: 1;
				transition: transform 80ms $ease;
				&:hover{
					transform: scale(1.4);
				}
				&.swiper-pagination-bullet-active{
					background: $green;
				}
			}
		}
	}
	.workswipe{
		.swiper-wrapper{
			transition-timing-function: $ease;
		}
		&__item{
			width: 32%;
			figure{
				img{
					width: 100%;
				}
			}
			dl{
				@include flex();
				align-items: center;
				justify-content: flex-start;
				margin: 10px 0 0;
				dt{
					background: $green;
					padding: 3px 15px;
					color: #FFF;
					font-size: 14px;
					margin: 0 20px 0 0;
					@media screen and (max-width: $tab){
						font-size: 12px;
						margin: 0 12px 0 0;
					}
				}
				dd{
					font-size: 16px;
					@media screen and (max-width: $tab){
						font-size: 14px;	
					}
				}
			}
		}
	}
	.kessan{
		font-size: 56px;
		font-weight: 700;
		text-align: center;
		background: $glay;
		padding: 8px 15px;
		margin: 48px 0 0;
		@media screen and (max-width: $tab){
			font-size: 40px;	
		}
		@media screen and (max-width: $phone){
			font-size: 20px;	
		}
		strong{
			color: $green;
		}
	}
	.localnav{
		margin: 0 0 56px;
		@media screen and (max-width: $phone){
			margin: 0 0 32px;	
		}
		&__list{
			@include flex();
			justify-content: flex-start;
			margin: 0 0 0 -32px;
			@media screen and (max-width: $tab){
				margin: 0 0 0 -16px;	
			}
			li{
				width: 20%;
				padding: 0 0 0 32px;
				margin: 32px 0 0;
				@media screen and (max-width: $tab){
					padding: 0 0 0 16px;	
				}
				@media screen and (max-width: $phone){
					width: 50%;
					margin: 0 0 15px;
				}
				&:nth-child(-n+5){
					margin-top: 0;
				}
				a{
					background: $glay;
					font-size: 17px;
					padding: 8px 0;
					color: $normal;
					display: block;
					text-align: center;
					border-radius: 2px;
					&:hover{
						color: #FFF;
						background: $green;
					}
				}
			}
		}
	}
	.tablebox{
		margin: 0 0 72px;
		position: relative;
		@media screen and (max-width: $tab){
			margin: 0 0 48px;	
		}
		@media screen and (max-width: $phone){
			margin: 0 0 32px;	
		}
		&:last-of-type{
			margin: 0;
		}
		&__target{
			position: absolute;
			top: -$pc-head;
			left: 0;
			pointer-events: none;
		}
		&__overflow{
			@media screen and (max-width: $phone){
				width: 100%;
				overflow: auto;
				position: relative;
				&:before{
					content: '横にスクロールしてご覧下さい';
					display: block;
					margin: 0 0 12px;
					padding: 0 0 0 22px;
					font-size: 13px;
				}
				&:after{
					content: "\f362";
					font-size: 15px;
					color: $green;
					font-weight: 900;
					font-family: "Font Awesome 5 Free";
					position: absolute;
					top: 0;
					left: 0;
				}
			}
			.tablestyle{
				border: $border 1px solid;
				@media screen and (max-width: $phone){
					display: table;
					width: 200%;
				}
				tbody,
				thead{
					@media screen and (max-width: $phone){
						display: table-row-group;
					}
					tr{
						@media screen and (max-width: $phone){
							display: table-row;
						}
						&:nth-child(2n+2){
							background: lighten($glay,2%);
						}
						th,td{
							padding: 20px;
							border: $border 1px solid;
							font-size: 16px;
							width: 15%;
							@media screen and (max-width: $phone){
								display: table-cell;
								vertical-align: middle;
								padding: 15px;
								font-size: 14px;
							}
							&:first-of-type{
								width: 40%;
							}
						}
						a{
							color: $normal;
							display: inline-block;
							padding: 0 32px 0 0;
							position: relative;
							&:hover{
								color: $green;
							}
							&:before{
								content: "\f0a9";
								font-family: "Font Awesome 5 Free";
								font-weight: 900;
								position: absolute;
								top: 52%;
								right: 0;
								font-size: 13px;
								transform: translateY(-50%);
								color: $green;
							}
						}
					}
				}
				thead{
					background: lighten($glay,2%);
					border-bottom: $border 4px solid;
					font-weight: 700;
				}
			}
		}
	}
}


.site-works-single{
	h2{
		&.worktitle{
			border-bottom: $border 1px solid;
			font-size: 24px;
			font-weight: 700;
			padding: 0 0 4px;
			margin: 0 0 24px;
			@media screen and (max-width: $phone){
				font-size: 18px;
				padding: 0 0 6px;
				margin: 0 0 16px;
			}
			&:before{
				content: attr(data-text);
				font-size: 14px;
				color: #FFF;
				background: $green;
				display: inline-block;
				margin: 0 20px 0 0;
				padding: 3px 16px;
				transform: translateY(-2px);
				@media screen and (max-width: $phone){
					font-size: 13px;
					margin: 0 12px 0 0;
				}
			}
		}
	}
	figure.main{
		margin: 0 0 40px;
		@media screen and (max-width: $phone){
			margin: 0 0 24px;	
		}
		img{
			width: 100%;
		}	
	}
	.tablestyle{
		border: $border 1px solid;
	}
}